import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import {useNavigate,useParams } from 'react-router-dom';


import CsDetailsHeader from 'layouts/common_components/cs_details_header';
import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';

import "react-datepicker/dist/react-datepicker.css";
import  secureLocalStorage  from  "react-secure-storage";

function Add_business_enquiry() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);

  const [clientMobile,setClientMobile] = useState("");
  const [clientName,setClientName] = useState('');
  const [clientBusinessName,setClientBusinessName] = useState('');
  const [clientEmail,setClientEmail] = useState('');
  const [clientCity,setClientCity] = useState('');
  
  const [clientAddress,setClientAddress] = useState();
  const [clientInfo,setClientInfo] = useState();
  const [isDivShow,setDivShow] = useState(false);
  const [cityList,setCityList] = useState([]);
  const [selectedCity,setSelectedCity] = useState();

  const [product,setProduct]=useState([]);
  const [selectedProduct,setSelectedProduct] = useState();
  const [checkexists,setcheckexists] = useState();

  const [service,setService]=useState([]);
  const [selectedService,setSelectedService] = useState();
    
  const [category,setCategory]=useState([]);
  const [selectedCategory,setSelectedCategory] = useState();
  const [clientInfodata,setClientInfodata] = useState({});

  


  const [validationErrors, setValidationErrors] = useState({});
  const {details} = useParams();
  const {id}      = useParams();
  const resetFollowupFields = ()=>
  {
    setClientMobile('');
    setClientName('');
    setClientBusinessName('');
    setClientEmail('');
    setClientAddress('');
    setSelectedProduct('');
    setSelectedCity('');
    setSelectedService('');
    setSelectedCategory('');
    
  }

  const submitForm = async() =>
  {
      const errors = {};
      const formData = new FormData();
      formData.append('mobile',clientMobile);
      formData.append('name',clientName);
      formData.append('business_name',clientBusinessName);
      formData.append('email',clientEmail);
      formData.append('group',selectedCity);
      formData.append('city',clientCity);
      formData.append('address',clientAddress);
      formData.append('product_id',selectedProduct);
      formData.append('service_id',selectedService);
      formData.append('category_id',selectedCategory);
    
      formData.append('created_by',emp_id);
     
      if(!clientName)
      {
        errors.clientName = 'Name is required';
      }
      if(!clientBusinessName)
      {
        errors.clientBusinessName = 'Business Name is required';
      }

      if(!clientMobile)
      {
        errors.clientMobile = 'Mobile Number is required';
      }

      if(!selectedCity)
      {
        errors.selectedCity = 'Client Group is required';
      }
      
      if(!clientCity)
      {
        errors.clientCity = 'Client city is required';
      }

      if(!clientAddress)
      {
        errors.clientAddress = 'Address is required';
      }

      if(!selectedProduct)
      {
        errors.selectedProduct = 'Product is required';
      }

      if(!selectedService)
      {
        errors.selectedService = 'Service is required';
      }

      setValidationErrors(errors);
     
      if(Object.keys(errors).length > 0)
      {
        return; 
      }
      
      try 
      {
          const response = await dashboardApi.post('add-business-enquiry', formData);
            if(response.data.status === 200)
            {
                resetFollowupFields();
                Swal.fire({
                  icon: 'success',
                  text: response.data.message,
                });
            }
            else
            {
              Swal.fire({
              icon: 'error',
              text: response.data.message || 'An error occurred',
              });
            }
      }catch (error){
        Swal.fire({
            icon: 'error',
            text: 'An error occurred while creating the product',
        });
      } 
  } 

    useEffect(() => {
      if (!token) {
        navigate("/sign-in");
      }
    }, [token, navigate]);

    useEffect(()=>{
      checkClientsDetails();
      getCityDetails();
      getProduct();
    
    },[])

    
      
    const checkClientsDetails = async () =>
    {
      const errors = {};
      
      if(!clientMobile)
      {
        errors.chkmobileNo = 'Mobile no in required.';
      }
      if(clientMobile.length < 10)
      {
        errors.chkmobileNo = 'Invalied mobile no.';
      }

      if(clientMobile.length > 10)
      {
        errors.chkmobileNo = 'Invalied mobile no.';
      }
      setValidationErrors(errors);


      if (Object.keys(errors).length > 0) {
        return; 
      }
     
      if(clientMobile)
      {
        try {
          const response = await dashboardApi.get(`check-client-mobile-number/${clientMobile}`);
          if (response.data.status === 200)
          {
            setDivShow(false);
            setClientInfo(response.data.message);
            setClientInfodata(response.data.data);
          } 
          else
          {
            setDivShow(true);
            setClientInfo('');
            setClientInfodata('');

          }
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }
    }
  
    function checkMobileNumber()
    {
      checkClientsDetails();
    }

    const checkInput = (e) => {
      const number = e.target.value.replace(/\D/g, "");
      setClientMobile(number);
    };

    const getCityDetails= async()=>
    {
      try {
          const response = await dashboardApi.get(`get-city-details`);
          if (response.data.status === 200)
          {
            setCityList(response.data.data);
          } 
          else
          {
           console.log('Data not found');
          }
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
    }

    const getProduct = async () => 
    {
      try{
        const response = await dashboardApi.get(`sales-product-list/active`);
        if(response.data.status === 200)
        {
          setProduct(response.data.data);
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
        }
        setLoading(false);
      }
      catch (error)
      {
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
    };

    useEffect(()=>{
      if(selectedProduct)
      {
        const fetchService = async () => {
          try {
            const response = await dashboardApi.get(`get-service-list-by-product-id/${selectedProduct}`);
            if (response.data.status === 200) {
              setService(response.data.data);
            } else {
              console.error('Error fetching countries:', response.data.message);
            }
            setLoading(false);
          } catch (error) {
            console.error('Error fetching countries:', error);
            setLoading(false);
          }
        }
        fetchService();
      }
    },[selectedProduct]);


  
      useEffect(()=>{
          if(selectedService)
          {
          const fetchCategory = async () => {
            try {
              const response = await dashboardApi.get(`get-category-list-by-service-id/${selectedService}`);
              if (response.data.status === 200) {
                  setCategory(response.data.data);
              } else {
                  console.error('Error fetching countries:', response.data.message);
              }
              setLoading(false);
              } catch (error) {
              console.error('Error fetching countries:', error);
              setLoading(false);
              }
          }
          fetchCategory();
          }
      },[selectedService]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                  <div className="row">
                      <div class="col-sm-11">
                          <CsDetailsHeader></CsDetailsHeader>
                      </div>
                      <div class="col-sm-1">
                        <Link className='btn btn-info' target="_blank" to="/add-enquiry">
                            +
                        </Link>
                      </div>
                  </div>
                  
                  <div className="row">
                    <div className="col-sm-6">
                        <label>Check Mobile No</label>
                        <TextField type="text"  maxLength={10}  className='form-control' value={clientMobile} onChange={(e) =>checkInput(e)} ></TextField>
                        <p style={{color:'red'}}>{clientInfo}</p>

                        { !details && validationErrors.chkmobileNo && (
                              <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.chkmobileNo}</div>
                        )}
                        {
                        checkexists == 'Yes' && details && 
                        <p style={{color:'red'}}>This Number Is Match With Another Record</p>
                      }
                    </div>

                    {!details && 
                    <div className="col-sm-6">
                        <Button style={{marginTop:'35px',float:'right'}} onClick={checkMobileNumber}>Check Client Details</Button>
                    </div>
                    }
                     {clientInfodata && clientInfodata.length > 0 && (
                  <>
                    {clientInfodata.map((client, index) => (
                      <div className="d-flex flex-wrap" key={index}>
                        <div className="col-sm-3 d-flex flex-column px-0"> <strong className='text-success'>Client Name</strong> <small> {client.client_name}</small></div>
                        <div className="col-sm-3 d-flex flex-column px-0"> <strong className='text-success'>Company Name</strong> <small> {client.business_name || 'N/A'}</small></div>
                        <div className="col-sm-3 d-flex flex-column px-0"> <strong className='text-success'>Executive Name</strong> <small> {client.emp_name || 'N/A'}</small></div>
                        <div className="col-sm-3 d-flex flex-column px-0"> <strong className='text-success'>Status</strong> <small> {client.status == 1 ? 'Verified' : client.status == 2 ? 'Active' : client.status == 3 ? 'Inactive' : client.status == 4 ? 'expire' : client.status == 5 ? 'Blacklist' : client.status == 6 ? 'isDeleted' : 'Pending'}</small></div>
                      </div>
                    ))}
                  </>
                )}
                  
                    <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                    
                        <p style={{ margin: "0 10px" }}>Basic Details</p>
                    
                        <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                    </div>
                  </div>


                {isDivShow && (
                <div className="row">
                  <div class="col-sm-6">
                      <label>Client Name</label>
                      <TextField type="text" className='form-control' value={clientName} onChange={(e)=>setClientName(e.target.value)}></TextField>
                      {validationErrors.clientName && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientName}</div>
                      )}
                  </div>

                  <div class="col-sm-6">
                      <label>Business Name</label>
                      <TextField type="text" className='form-control' value={clientBusinessName} onChange={(e)=>setClientBusinessName(e.target.value)}></TextField>
                      {validationErrors.clientBusinessName && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientBusinessName}</div>
                      )}
                  </div>

                  <div class="col-sm-6">
                      <label>Mobile Number</label>
                      <TextField type="text" className='form-control' value={clientMobile} readOnly></TextField>
                      {validationErrors.clientMobile && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientMobile}</div>
                      )}
                  </div>

                  <div class="col-sm-6">
                      <label>Email Id</label>
                      <TextField type="text" className='form-control' value={clientEmail} onChange={(e)=>setClientEmail(e.target.value)}></TextField>

                  </div>

                  <div class="col-sm-6">
                    <label>Select Group</label>
                    <select className="form-control"
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                        >
                        <option value=''>Select City</option>
                        {cityList.map((city) => (
                        <option key={city.group_id} value={city.group_id}>
                        {city.name}
                        </option>
                        ))}
                    </select>
                    {validationErrors.selectedCity && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedCity}</div>
                      )}
                  </div>

                  <div class="col-sm-6">
                      <label>City</label>
                      <TextField type="text" className='form-control' value={clientCity} onChange={(e)=>setClientCity(e.target.value)}></TextField>
                      {validationErrors.clientCity && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientCity}</div>
                      )}
                  </div>

                  <div class="col-sm-12">
                      <label>Address</label>
                      <TextField type="text" className='form-control' value={clientAddress} onChange={(e)=>setClientAddress(e.target.value)}></TextField>
                      {validationErrors.clientAddress && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientAddress}</div>
                      )}
                  </div>

                  <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                      <p style={{ margin: "0 10px" }}>Product Details</p>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  </div>

                  <div className="col-sm-6">
                    <label>Salect Product</label>
                    <select className="form-control" value={selectedProduct}
                          onChange={(e) => setSelectedProduct(e.target.value)}>
                          <option value=''>Select Product</option>
                          {product.map((pro) => (
                          <option key={pro.id} value={pro.id}>
                          {pro.product_name}
                          </option>
                          ))}
                    </select>
                    {validationErrors.selectedProduct && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedProduct}</div>
                      )}
                  </div>
                  {selectedProduct &&(
                  <div className="col-sm-6">
                      <label>Select Service</label>
                      <select className="form-control"
                              value={selectedService}
                              onChange={(e) => setSelectedService(e.target.value)}
                              >
                              <option value=''>Select Service</option>
                              {service.map((ser) => (
                              <option key={ser.id} value={ser.id}>
                              {ser.service_name}
                              </option>
                              ))}
                      </select>
                      {validationErrors.selectedService && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedService}</div>
                      )}
                  </div>
                  )}

                  {category != '' && selectedService &&(
                  <div className="col-sm-6">
                      <label>Select Category</label>
                      <select className="form-control"
                          value={selectedCategory}
                          onChange={(e) => setSelectedCategory(e.target.value)}
                          >
                          <option value=''>Select Category</option>
                          {category.map((cate) => (
                          <option key={cate.id} value={cate.id}>
                          {cate.category_name}
                          </option>
                          ))}
                      </select>
                      
                  </div>
                )}

                {selectedCategory &&(
                  <div className="col-sm-6">
                      <br/>

                      <button className="btn btn-primary" onClick={submitForm}>Add Business Enquiry</button>
                      
                  </div>
                )}

                </div>
              )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Add_business_enquiry;