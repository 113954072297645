import React, { useState,useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";

// Data
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";
import StockDetailsHeader from "layouts/common_components/stock_details_header";


function AddVendor() {
  const token = secureLocalStorage.getItem('token');
  const emp_id = secureLocalStorage.getItem('emp_id');
  const navigate = useNavigate();
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [name,setname] = useState('');
  const [address,setaddress] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  const savevendor = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!setname) {
      errors.name = 'name is required';
    }
     if (!setphone) {
      errors.mobile = 'phone is required';
    }
     if (!setemail) {
      errors.email = 'Email is required';
    }
   if (!setaddress) {
      errors.address = 'Address is required';
    }
    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
    const formData = new FormData()
    formData.append('name', name);
    formData.append('mobile', phone);
    formData.append('email', email);
    formData.append('address', address);
    formData.append('emp_id',emp_id);
    setIsSubmitting(true);

    try {
      const response = await dashboardApi.post('create-vendor', formData);

      if (response.data.status === 200) {
        //console.log('iiii');
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        navigate("/vendor-list")
      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the country',
      });
    }
   finally {
        setIsSubmitting(false);
      }
  };
}
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <StockDetailsHeader/>
            <Card>
              <div className="card-body">
                <form onSubmit = {savevendor}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>vendor Name</label>
                          <input
                            type="text"
                            className="form-control"
                            value = {name}
                            onChange={(event)=>{
                              setname(event.target.value)}}
                            placeholder="Enter Vendor Name"
                          />
                          {validationErrors.name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.name}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Mobile No</label>
                          <input
                            type="text"
                            className="form-control"
                            value = {phone}
                            onChange={(event)=>{
                              setphone(event.target.value)}}
                            placeholder="Enter Phone"
                          />
                          {validationErrors.mobile && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.mobile}</div>
                             )}
                        </div>
                      </div>
                       <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Email</label>
                          <input
                            type="text"
                            className="form-control"
                            value = {email}
                            onChange={(event)=>{
                              setemail(event.target.value)}}
                            placeholder="Enter email"
                          />
                          {validationErrors.email && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.email}</div>
                             )}
                        </div>
                      </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-6">
                         <label htmlFor="short name" style={{ fontSize: '16px', height: '20px' }}>Address</label>
                         <textarea rows={5} cols={5} style={{ height: '50px' }}
                          value={address}
                           onChange={(e) => setaddress(e.target.value)}
                           className="form-control"
                           placeholder="Address"
                          />
                        {validationErrors.address && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.address}</div>
                       )}
                       </div>
                       
                      
                     </div>
                      </div>
                      <div className="row">
                      <div className="col-md-12 text-center mt-5">
                        <button
                        variant="primary"
                        className="mt-2 btn btn-info"
                        size="lg"
                        block="block"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        )}
                        {isSubmitting ? 'Saving...' : 'Save'}
                      </button>
                      </div>
                    </div>
                      
                </form>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddVendor;
