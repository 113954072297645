import React, { useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import Card from "@mui/material/Card";

function StockDetailsHeader() {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);

  // Function to set active tab when clicked
  const handleTabClick = (tabPath) => {
    setActiveTab(tabPath);
  };

  return (
    <Card>
      <div className="card-body">
              <Link
                to='/vendor-list'
                className={`btn btn-sm ${activeTab === '/vendor-list' ? 'btn-primary' : 'btn-outline-primary'}`}
                onClick={() => handleTabClick('/vendor-list')}
              >
                Vendor Details
              </Link>
              &nbsp; &nbsp;
           
              <Link
                to='/category-list'
                className={`btn btn-sm ${activeTab === '/category-list' ? 'btn-primary' : 'btn-outline-primary'}`}
                onClick={() => handleTabClick('/category-list')}
              >
                Category 
              </Link>
               &nbsp; &nbsp;
           
              <Link
                to='/stock-list'
                className={`btn btn-sm ${activeTab === '/stock-list' ? 'btn-primary' : 'btn-outline-primary'}`}
                onClick={() => handleTabClick('/stock-list')}
              >
                Stock Details
              </Link>
               &nbsp; &nbsp;
          
              <Link
                to='/assign-list'
                className={`btn btn-sm ${activeTab === '/assign-list' ? 'btn-primary' : 'btn-outline-primary'}`}
                onClick={() => handleTabClick('/assign-list')}
              >
                Assign Stock
              </Link>
               &nbsp; &nbsp;
              <Link
                to='/return-list'
                className={`btn btn-sm ${activeTab === '/return-list' ? 'btn-primary' : 'btn-outline-primary'}`}
                onClick={() => handleTabClick('/return-list')}
              >
                Return Request
              </Link>
               &nbsp; &nbsp;
              <Link
                to='/raise-asset-request'
                className={`btn btn-sm ${activeTab === '/raise-asset-request' ? 'btn-primary' : 'btn-outline-primary'}`}
                onClick={() => handleTabClick('/raise-asset-request')}
              >
                Raise Request
              </Link>
               &nbsp; &nbsp;
              <Link
                to='/stock-repair'
                className={`btn btn-sm ${activeTab === '/stock-repair' ? 'btn-primary' : 'btn-outline-primary'}`}
                onClick={() => handleTabClick('/stock-repair')}
              >
                Repair Details
              </Link>
            
        </div>
    </Card>
  );
}

export default StockDetailsHeader;
