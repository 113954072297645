// @mui material components
import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControl from '@mui/material/FormControl';
import  secureLocalStorage  from  "react-secure-storage";

import Button from "@mui/material/Button";
// Data
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from '@mui/material/InputLabel';
import Pagination from 'react-bootstrap/Pagination';
//import 'bootstrap/dist/css/bootstrap.min.css';
import ReactPaginate from 'react-paginate';
import TicketRaiseHeader from 'layouts/common_components/ticket_raise_header';


function ReceivedList() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [receivelist, setreceivelist] = useState([])
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState('');
  const [remark,setremark] = useState('');
  const [start_date,setstart_date] = useState('');
  const [end_date,setend_date] = useState('');
  const [delivery_date,setdelivery_date] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(5);
  const [opensendmodal,setopensendmodal] = useState(false);
  const[new_time,setnew_time] = useState('');
  const[ticketid,setticketid] = useState('');
  const [is_manager,set_is_manager] = useState();
 // setSelectedOptions(1)

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
    fetchData();

  },[])
   const fetchData = async (page = 0) => {
    try {
      const endpoint = `ticket-receive-list/${emp_id}`;
      const response = await dashboardApi.get(endpoint, {
     params: {
       per_page: postPerPage,
       page: page + 1,
      }
     });


      if (response.data.status === 200) {
        //setreceivelist(response.data.data);
         const responseData = response.data.data;
        const ticketdata = responseData.data || responseData;
        setreceivelist(ticketdata);
        setNumber(response.data.last_page);
        set_is_manager(response.data.is_reporting_manager);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
const handleOpenModal = async (ticketId, departmentId,group) => {
  //alert(group);
    try {
        const endpoint = `get-all-manager-employee/${emp_id}`;
         const response = await dashboardApi.get(endpoint)
        //console.log(response);
      if (response.data.status === 200) {
         setSelectedDepartmentId(response.data.data);
      } 
      setLoading(false);
    } catch (error) {
      console.error('Error fetching leave:', error);
      setLoading(false);
    }
    setSelectedTicket(ticketId);
   // setSelectedOptions([]);
    //setSelectedDepartmentId(departmentId);
    setOpenModal(true);
  };
  //console.log(selectedDepartmentId);

  const handleCloseModal = () => {
    setSelectedTicket(null);
    //setSelectedDepartmentId(null);
    setSelectedOptions('');
    setOpenModal(false);
    setremark('')
    setopensendmodal(false);
    setnew_time('');
    setValidationErrors({});
   // se
  };
   const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
    fetchData(selected.selected);
    //fetchData(selected.selected);
   // console.log(selected);


  };
   const savedata = async (e) => {
    e.preventDefault();
    const errors = {};
    if (setSelectedOptions.length === 0) {
      errors.selected_team = 'Please select at least one Member';
    //alert();
    }
    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      return; 
    }
   // console.log(selectedOptions);

    const formData = new FormData();
    formData.append('selected_team', selectedOptions);
    formData.append('remark', remark);
    formData.append('ticket_id',selectedTicket);
    formData.append('emp_id',emp_id);
   // setIsSubmitting(true);

    try {
      const response = await dashboardApi.post('save-assign-ticket', formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        //navigate("/ticket-receive");
        handleCloseModal();
        fetchData();

      } 
       else if (response.data.status === 400) {
        Swal.fire({
          icon: 'error',
          text: response.data.message,
        });
         
        //fetchData();
        
      }

      else if (response.data.status === 201) {
        Swal.fire({
          icon: 'error',
          text: response.data.message,
        });
         handleCloseModal();
        //fetchData();
        
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the enquiry',
      });
    } 
  };

  const sendreq = (id)=>{
    setopensendmodal(true);
    setticketid(id);

  }
  const sendrequest = async(e)=>{
     e.preventDefault();
    const errors = {};
    if (!new_time) {
      errors.new_time = 'Time is Required';
    //alert();
    }
    if (!remark) {
      errors.remark = 'Remark is Required';
    //alert();
    }

    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      return; 
    }
   // console.log(selectedOptions);

    const formData = new FormData();
    formData.append('new_time', new_time);
    formData.append('remark', remark);
    formData.append('emp_id',emp_id);
    formData.append('ticket_id',ticketid)
   // setIsSubmitting(true);

    try {
      const response = await dashboardApi.post('save-new-time-request', formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        //navigate("/ticket-receive");
        handleCloseModal();
        fetchData();

      } else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the enquiry',
      });
    } 


  }

  const handlePick = async (ticket_id)=>{
     const isConfirm = await Swal.fire({
      title: 'Are you sure?',
      text: "Are You Want To Pick This Ticket!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      return result.isConfirmed
    });
    
    if (!isConfirm) {
      return;
    } 

      const formData = new FormData();
      formData.append('ticket_id', ticket_id);
      formData.append('emp_id',emp_id)
      try {
        const response = await dashboardApi.post('pick-ticket', formData);
        if (response.data.status === 200) {
          Swal.fire({
            icon: 'success',
            text: response.data.message,
          });
           navigate('/ticket-assign')
        }
         else if (response.data.status === 400) {
          Swal.fire({
            icon: 'error',
            text: response.data.message,
          });
        }

         else {
          Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          text: 'An error occurred while saving details',
        });
      }





  }

//console.log(raiselist);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <TicketRaiseHeader/>
              <div className="card-body">
                 {loading ? (
                  <p>Loading...</p>
                ) : (
                 <div className="table-responsive">
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Ticket Id</td>
                  <td>From Dept</td>
                  <td>Attribute</td>
                  <td>Subattribute</td>
                  <td>Action Time</td>
                  <td>Completion Time</td>
                  <td>Description</td>
                  <td style={{minWidth:'200px'}}>Action</td>
               </tr>
               {receivelist.map((ticket, index) => (
                <tr key={ticket.id}>
                  <td>{index + 1}</td>
                  <td>{ticket.ticket_id}</td>
                  <td>{ticket.department_name}</td>
                  <td>{ticket.attribute_name}</td>
                  <td>{ticket.name}</td>
                  <td>{ticket.action_time} hour</td>
                  <td>{ticket.completion_time} hour</td>
                 <td>
                  <Link to={`/view-ticket-status/${ticket.ticket_id}`} className='btn btn-success me-2 btn-sm'>
                             View
                  </Link>
                  </td>
                 <td>
  {ticket.close_status === 0 && is_manager === 'Yes' ? (
    <>
      <Link
        className="btn btn-info btn-sm"
        onClick={() => handleOpenModal(ticket.ticket_id, ticket.request_to_dept, ticket.group_id)}
        to="#"
      >
        Assign
      </Link>
      &nbsp;
      {
        ticket.created_by !== emp_id && (
         <button className="btn btn-success btn-sm" onClick={() => handlePick(ticket.ticket_id)}>
          Action
        </button>
        )
        }
    &nbsp;
      <button
        className="btn btn-info btn-sm"
        onClick={() => sendreq(ticket.ticket_id)}
      >
        Time Change Request
      </button>
    </>
  ) : ticket.close_status === 0 && is_manager === 'No'  ? (
    <button className="btn btn-success btn-sm" onClick={() => handlePick(ticket.ticket_id)}>
      Action
    </button>
  ) : (
    <Link className="btn btn-danger me-2" to="#">
      Closed
    </Link>
  )}
</td>


               </tr>
              ))}
            </table>
            </div>
             )}
              {receivelist.length > 0 && (
                <Pagination className="custom-pagination">
                      <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
                  )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
       <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'500px' } }}
      >
        <DialogTitle>Assign To Team</DialogTitle>
        <DialogContent>
          <form>
            <div>
              <label>Select Team:</label>
              <select className="form-control"
                             value={selectedOptions}
                             onChange={(e) => setSelectedOptions(e.target.value)}
                              >
                            <option value=''>Select Team</option>
                             {selectedDepartmentId.map((item) => (
                             <option key={item.emp_id} value={item.emp_id}>
                              {item.emp_fname}  {item.emp_lame}
                              </option>
                              ))}
                          </select>
            
              {validationErrors.selected_team&& (
                 <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selected_team}</div>
                )}
            </div>
            <div>
              <label>Remark:</label>
              <TextField
                type="text"
                fullWidth
                 value={remark}
                onChange={(e) => setremark(e.target.value)}
              />
             {validationErrors.remark&& (
                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remark}</div>
                )}
            </div>
          </form>
        </DialogContent>
        <DialogActions>
           <Link className="btn btn-danger" onClick={handleCloseModal}>
            Close
          </Link>
          <Link className="btn btn-info" onClick={savedata}>
            Assign
          </Link>
        </DialogActions>
      </Dialog>

       <Dialog
        open={opensendmodal}
       // onClose={handleCloseModal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px' } }}
      >
        <DialogTitle>Send Request</DialogTitle>
        <DialogContent>
          <form>
            <div>
             <label>new Required Time(In Hour):</label>
              <input type="number"
               className="form-control"
               value={new_time}
                onChange={(e) => setnew_time(e.target.value)}

               />

            
              {validationErrors.new_time&& (
                 <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.new_time}</div>
                )}
                           </div>

                    <div className ="row">
                        <div className="form-group col-md-12">
                          <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Remark</label>
                           <textarea rows={5} cols={5} style={{height:'50px'}}
                            value={remark}
                            onChange={(e) => setremark(e.target.value)}
                            className = "form-control"


                           />
                            {validationErrors.remark&& (
                           <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remark}</div>
                          )}
                    </div>
                    </div>
          </form>
        </DialogContent>
        <DialogActions>
           <Link className="btn btn-danger" onClick={handleCloseModal}>
            Close
          </Link>
          <Link className="btn btn-info" onClick={sendrequest}>
           Update
          </Link>
        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}

export default ReceivedList;