import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import ReportsDetailsHeader from 'layouts/common_components/reports_details_header';

import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import secureLocalStorage from "react-secure-storage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import "layouts/css/global.css";
import { parseISO, isBefore, format } from 'date-fns';


function Active_package_reports()
{
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingDowunload, setLoadingDowunload] = useState(false);
  
  const [packageReports, setPackageReports] = useState([]);
  const [exeDetails, setExeDetails] = useState([]);
  const [selectedEmp, setSelectedEmp] = useState('');
  const [currentPage, setCurrentPage] = useState(0); 
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(10);
  const [selectFromDate,setFilterDateFrom] = useState('');
  const [selectToDate,setFilterDateTo] = useState('');
  const [dateError, setError] = useState('');

  const [productList, setProductList] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [servicelist, setservicelist] = useState([]);
  const [service, setservice] = useState('');
  const [category_list,set_category_list] = useState([]);
  const [category,selectedcategory] = useState('');
  const [groupList, setGroupList] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState();
  const [clientStatus, setClientStatus] = useState();

  useEffect(()=>{
    getExeDetails();
    getGroupList();
    getProductList();
  },[]);

  useEffect(()=>{
    getAllActivePackage();
  },[currentPage])

  const handleFromDateChange = (e) => {
    setFilterDateFrom(e.target.value);
    validateDates(e.target.value, selectToDate);
  };

  const handleToDateChange = (e) => {
    setFilterDateTo(e.target.value);
    validateDates(selectFromDate, e.target.value);
  };

  const validateDates = (from, to) => {
    if (from && to) {
      const fromDateObj = parseISO(from);
      const toDateObj = parseISO(to);

      if (isBefore(toDateObj, fromDateObj)) {
        setError('To date cannot be before from date.');
        setFilterDateTo('');
      } else {
        setError('');
      }
    }
  };

  const getGroupList = async () => {
    try {
      const response = await dashboardApi.get(`get-group-list`);
      if (response.data.status === 200) {
        setGroupList(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching group list:', error);
    }
  }

  const getProductList = async () => {
    try {
      const type = "active";
      const response = await dashboardApi.get(`sales-product-list/${type}`);
      if (response.data.status === 200) {
        setProductList(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching product list:', error);
    }
  }

  useEffect(() => {
    if (selectedProduct) {
      const fetchData = async () => {
        try {
          const endpoint = `get-service-list-by-product-id/${selectedProduct}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            setservicelist(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (service) {
      const fetchData = async () => {
        try {
          const endpoint = `get-category-list-by-service-id/${service}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            set_category_list(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [service]);

  useEffect(() => {
    getAllActivePackage();
  }, [currentPage,selectedProduct,service,category,selectedGroup,selectedEmp])

  const getAllActivePackage = async () =>
  {
    try
    {
      const response = await dashboardApi.get(`get-all-active-package`,{
        params:{
          emp_id:selectedEmp,
          product:selectedProduct,
          service:service,
          category:category,
          group: selectedGroup,
          page: currentPage + 1,
        }
      });

      if(response.data.status === 200)
      {
        const responseData = response.data.data;
        const packageData = responseData.data || responseData;
        setPackageReports(packageData);
        setNumber(responseData.last_page);
      }
      else
      {
        console.error('Error fetching countries:');
      }
      setLoading(false);
    }
    catch (error)
    {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  }

   

    const getExeDetails = async () => 
    {
      try{
          const response = await dashboardApi.get(`get-executive-details/${emp_id}`);
          if (response.data.status === 200) {
            setExeDetails(response.data.data);
          } else {
            console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
      }catch (error){
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
    }

    const handlePageClick = (selected) => {
      setCurrentPage(selected.selected);
    };

    const downloadPackageCSV = async()=>
    {
      setLoadingDowunload(true);
      const ipAddress = await getIpAddress();
      const deviceInfo = getDeviceInfo();
      try {
        const response = await dashboardApi.get('get-active-package-csv-reports', {
          params: 
          {
            emp_id: selectedEmp,
            downloadBy: emp_id,
            product:selectedProduct,
            service:service,
            category:category,
            group: selectedGroup,
            ipAddress:ipAddress,
            deviceInfo:deviceInfo,
          },
          responseType: 'blob',
      });
        const blob = new Blob([response.data], { type: 'text/csv'});
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = `active_package_${new Date().toISOString()}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setLoadingDowunload(false);
      } catch (error) {
        setLoadingDowunload(false);
        console.error('Error downloading the CSV file', error);
      }
    }

      const getIpAddress = async () => {
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            return response.data.ip;
        } catch (error) {
            console.error('Error fetching IP address', error);
            return null;
        }
      };
    
      const getDeviceInfo = () => {
        return {
            userAgent: navigator.userAgent,
            platform: navigator.platform,
            appVersion: navigator.appVersion,
        };
      };



  return(
    <DashboardLayout>
    <DashboardNavbar />
    <MDBox pt={3} pb={3}>
      <Card>
        <div className="card-body p-3">
          <ReportsDetailsHeader></ReportsDetailsHeader>
          <div className='d-flex flex-wrap align-items-end'>
            <div className='col-sm-2 col-6'>
              <small>Select Eexecutive</small>
              <select className="form-control form-select"
                value={selectedEmp}
                onChange={(e) => setSelectedEmp(e.target.value)}
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              >
                <option value=''>Select Eexecutive</option>
                {exeDetails.map((ed) => (
                  <option key={ed.emp_id} value={ed.emp_id}>
                    {ed.emp_fname} {ed.emp_lname}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-sm-2 col-6">
              <small>Product</small>
              <select className="form-control form-select" value={selectedProduct} onChange={(e) => { setSelectedProduct(e.target.value) }} style={{ width: '100%', padding: '8px', fontSize: '11px' }}>
                <option value="">Select Product</option>
                {productList && productList.map((pro) => (
                  <option key={pro.id} value={pro.id}>{pro.product_name}</option>
                ))}
              </select>
            </div>

            <div className="col-sm-2 col-6 p-1">
                <small>Service</small>
                <select
                  className="form-control form-select"
                  value={service}
                  onChange={(e) => setservice(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option value="">Select Service</option>
                  {servicelist.map((ser) => (
                    <option key={ser.id} value={ser.id}>
                      {ser.service_name}
                    </option>
                  ))}
                </select>
            </div>

            <div className="col-sm-2 col-6 p-1">
              <small>Category</small>
              <select
                className="form-control form-select"
                value={category}
                onChange={(e) => selectedcategory(e.target.value)}
                aria-label="Default select example"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              >
                <option value="">Select Category</option>
                {category_list.map((row) => (
                  <option key={row.id} value={row.id}>
                    {row.category_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-sm-2 col-6">
              <small>Group</small>
              <select className="form-control form-select" value={selectedGroup} onChange={(e) => { setSelectedGroup(e.target.value) }} style={{ width: '100%', padding: '8px', fontSize: '11px' }}>
                <option value="">Select Group</option>
                {groupList && groupList.map((group) => (
                  <option key={group.group_id} value={group.group_id}>{group.name}</option>
                ))}
              </select>
            </div>
          </div>

          <div className='d-flex justify-content-between align-items-center px-2 mt-3'>
            <h5 className='m-0'>Active Package Reports</h5>
            {loadingDowunload ? (
            <p style={{ align: 'center' }}>
              <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{ height: '80px', width: 'auto' }}></img>
            </p>
            ) : (
            <button className="btn btn-primary" onClick={downloadPackageCSV}>
              <span class="material-icons-outlined">
                file_download
              </span>
            </button>
            )}
          </div>




          {loading ? (
            <p style={{ align: 'center' }}>
              <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{ height: '80px', width: 'auto' }}></img>
            </p>
          ) : (
            <div className='p-2' style={{ overflowY: "hidden" }}>
              <table className="table table-bordered table-hovered" style={{ marginTop: '20px' }}>

                <tr>
                  <td>S.No</td>
                  <td>Product Name</td>
                  <td>Service Name</td>
                  <td>Category Name</td>
                  <td>Group Name</td>
                  <td>Duration</td>
                  <td>Package Type</td>
                  <td>Package Name</td>
                  <td>Client Id</td>
                  <td>Company Id</td>
                  <td>Package Id</td>
                  <td>Total Lead</td>
                  <td>Sent Lead</td>
                  <td>Balance Lead</td>
                  <td>Start Date</td>
                  <td>Expected End Date</td>
                  <td>Active Days</td>
                  <td>Remaining Days</td>
                  <td>Sent Ratio</td>
                  <td>Due Status</td>
                  <td>Due Amount</td>
                  <td>Due Date</td>
                  <td>Cities</td>
                  <td>To Cities</td>

                  <td>No of Locality</td>
                  <td>Today Lead Sent</td>
                  <td>Max Per Day</td>
                  <td>Last Lead Sent Date</td>

                  <td>Status</td>
                </tr>

                {packageReports && packageReports.map((pr, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{pr.product_name}</td>
                    <td>{pr.service_name}</td>
                    <td>{pr.category_name}</td>
                    <td>{pr.group_name}</td>
                    <td>{pr.package_duration==1?'Lead Based':'Unlimited'}</td>
                    <td>{pr.package_type}</td>
                    <td>{pr.package_name}</td>
                    <td>{pr.client_id}</td>
                    <td>{pr.comp_id}</td>
                    <td>{pr.package_id}</td>
                    <td>{pr.total_lead}</td>
                    <td>{pr.sent_lead}</td>
                    <td>{pr.total_lead - pr.sent_lead}</td>
                    <td>{pr.package_start_date}</td>
                    <td>{pr.package_end_expected_date}</td>
                    <td>{pr.active_days}</td>
                    <td>{pr.remaining_days}</td>
                    <td>{pr.sent_ratio}</td>
                    <td>{pr.due_status==1?<span style={{color:'red'}}>Half Paid</span>:<span style={{color:'green'}}>Full Paid</span>}</td>
                    <td>{pr.due_amount}</td>
                    <td>{pr.due_date}</td>
                    <td>{pr.city_names}</td>
                    <td>{pr.to_city_names}</td>

                    <td>{pr.no_of_locality}</td>
                    <td>{pr.today_lead_send}</td>
                    <td>{pr.max_per_day}</td>
                    <td>{pr.last_lead_sent_date}</td>
                
                    <td>{pr.package_status == 1 ? 'Active' : pr.package_status == 2 ? 'Stop By Admin' : pr.sent_enquiry == 3 ? 'Stop due to due payment' : pr.sent_enquiry == 0 ? 'Pending' : ''}</td>
                  </tr>
                ))
                }

              </table>
              <Pagination className="custom-pagination">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={number}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  pageLinkClassName={"page-link-style"}
                  breakLinkClassName={"break-link-style"}
                  previousLinkClassName={"prev-link-style"}
                  nextLinkClassName={"next-link-style"}
                />
              </Pagination>
            </div>
          )}

        </div>
      </Card>
    </MDBox >
    <Footer />
  </DashboardLayout >
  );
}
export default Active_package_reports;