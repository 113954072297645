import React from 'react';
import { Link, useLocation } from "react-router-dom";
function FinanceDetailsHeader() {
  let location = useLocation();
  let currentPage = location.pathname;

  return (

    <div className='d-flex justify-content-between mb-3' style={{ overflowY: 'hidden' }}>
      <div className='px-1'>
        <Link style={currentPage === "/finance-admin" ? { backgroundColor: '#17A2B8',borderColor:'#17A2B8'} : { backgroundColor: "" }} className="btn btn-primary mb-2 float-end" to="/finance-admin">
          New Amount
        </Link>
      </div>
      <div className='px-1'>
        <Link style={currentPage === "/approved-amount" ? { backgroundColor: '#17A2B8',borderColor:'#17A2B8'} : { backgroundColor: "" }} className="btn btn-primary mb-2 float-end" to="/approved-amount">
          Approved Amount
        </Link>
      </div>
      <div className='px-1'>
        <Link style={currentPage === "/payment-history" ? { backgroundColor: '#17A2B8',borderColor:'#17A2B8'} : { backgroundColor: "" }} className="btn btn-primary mb-2 float-end" to="/payment-history">
          Payment History
        </Link>
      </div>
      <div className='px-1'>
        <Link style={currentPage === "/ledger-details" ? { backgroundColor: '#17A2B8',borderColor:'#17A2B8'} : { backgroundColor: "" }} className="btn btn-primary mb-2 float-end" to="/ledger-details">
          Ladger Details
        </Link>
      </div>
    </div>
  );
}


export default FinanceDetailsHeader;
