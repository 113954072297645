// @mui material components
import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";

import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";

function SalesDoc() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [documentlist, setdocumentlist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [packagetypelist,setpackagetypelist] = useState([]);
  const [selectedorgtype,setselectedorgtype] = useState([]);
  const [category,setcategory] = useState([]);
  const [selectedcategory,setselectedcategory] = useState([]);
  const [name, setname] = useState();
  const [checked,setchecked] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [editopenmodel,seteditopenmodel] = useState(false);
  const[id,setid] = useState('');
  const [org_type,setorg_type] = useState([]);
  const[productlist,setproductlist] = useState([]);
  const [selectedproduct,setselectedproduct] = useState('');
  const [servicedetailslist,getservicedetails] = useState([]);
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
    fetchDocument();
    productdetails();

  },[])
  useEffect(()=>{
    productcategory();

  },[selectedproduct])
  const fetchDocument = async () => {
    try {
      const endpoint = 'sales-document-list';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setdocumentlist(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

 useEffect(() => { 
    const fetchData = async () => {
      try {
        const endpoint = 'sales-package-type';
        const response = await dashboardApi.get(endpoint);
  
        if (response.data.status === 200) {
            setpackagetypelist(response.data.data);
        } else {
          console.error('Error fetching data:');
        }
       setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  const closemodel = ()=>{
   // alert('kk');
    setname(' ');
    setchecked(false);
    setselectedorgtype([ ]);
    setselectedcategory([ ]);
    setOpenModal(false);
    seteditopenmodel(false);
    setselectedproduct('');
    setValidationErrors({});


  }

  useEffect(()=>{
    const fetchorgtype = async()=>{
      try {
        const endpoint = 'get-organization-type';
        const response = await dashboardApi.get(endpoint);
  
        if (response.data.status === 200) {
          setorg_type(response.data.data);
        } else {
          console.error('Error fetching data:');
        }
       setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    fetchorgtype();
    },[])

  useEffect(() => { 
    const fetchData = async () => {
      try {
        const endpoint = 'product-category';
        const response = await dashboardApi.get(endpoint);
  
        if (response.data.status === 200) {
            setcategory(response.data.data);
        } else {
          console.error('Error fetching data:');
        }
       setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);


  const productcategory = async () => {
    try {
      const response = await dashboardApi.get('product-category', {
        params: {
          product:selectedproduct
        },
      });
      if (response.data.status === 200) {
        const responseData = response.data.data;
        getservicedetails(responseData);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };



const openpopup = ()=>{
    setOpenModal(true);

}
const editform = async(e)=>{
    setid(e);
        try {
            const endpoint = `sales-document-edit/${e}`;
            const response = await dashboardApi.get(endpoint);
           if (response.data.status === 200) {
            //console.log(response.data.data.department_id);
            setname(response.data.data.document_name);
            setselectedcategory(response.data.data.service_id);
            setselectedproduct(response.data.data.product_id);
            setselectedorgtype(response.data.data.org_id);
            if(response.data.data.is_required==1){
                setchecked(true)

            }
            else{
                setchecked(false)

            }
           //setQuantity(response.data.data.quantity);
          //setselectedemployee(assignToValues);
            } else {
              console.error('Error fetching data:');
            }
          // setLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
            //setLoading(false);
          }
          seteditopenmodel(true);




    

}
const productdetails = async () => {
  try {
    const endpoint = 'get-product-list';
    const response = await dashboardApi.get(endpoint);

    if (response.data.status === 200) {
      setproductlist(response.data.data);
    } else {
      console.error('Error fetching data:', response.data.error);
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};
const toggleStatus = async (documentId) => {
   // alert(documentId);
    const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "Are You Want To Change Status!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change it!'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }
    const response = await dashboardApi.get(`sales-document-status/${documentId}`);
    if (response.data.status === 200) {
      const currentStatus = response.data.data;
      const newStatus = currentStatus === 1 ? 0 : 1;
      const updateResponse = await dashboardApi.post('sales-document-status-change', {
        doc_id:documentId,
        status: newStatus,
      });
      if (updateResponse.data.status === 200) {
        // Update the local state with the new status
        setdocumentlist((prevState) =>
          prevState.map((docObj) =>
          docObj.id === documentId ? { ...docObj, status: newStatus } : docObj
          )
        );
      } else {
        console.error('Error updating status:', updateResponse.data.message);
      }
    } else {
      console.error('Error fetching current status:', response.data.message);
    }
  };
  const handleorgtype = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
    setselectedorgtype(selectedOptions);
  };

  const handlecategory = (e)=>{
    const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
    setselectedcategory(selectedOptions);
    

  }
  const savedocument = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!selectedproduct) {
      errors.product = 'Product is required';
    }
    if (!name) {
      errors.name = 'Document Name is required';
    }
    if (!selectedorgtype) {
      errors.orgtype = 'Org Type is required';
    }
    if (!selectedcategory) {
      errors.category = 'Category is required';
    }
    setValidationErrors(errors);
   if (Object.keys(errors).length === 0) {
    const formData = new FormData()
    formData.append('category',selectedcategory);
    formData.append('document_name',name);
    formData.append('is_required',checked? 1 : 0);
    formData.append('emp_id',emp_id);
    formData.append('product',selectedproduct)
    const selectedorgnization = Array.isArray(selectedorgtype)
      ? selectedorgtype
      : [selectedorgtype];

      selectedorgnization.forEach((Id) => {
      formData.append('org_type[]',Id);
    });

    const selectedcategory_ids = Array.isArray(selectedcategory)
    ? selectedcategory
    : [selectedcategory];

    selectedcategory_ids.forEach((Id) => {
    formData.append('category_array[]',Id);
  });
    //setIsSubmitting(true);

    try {
      const response = await dashboardApi.post('save-sales-document', formData);

      if (response.data.status === 200) {
        //console.log('iiii');
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
       // alert();
       closemodel();
       fetchDocument();

      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating',
      });
    }
   finally {
        //setIsSubmitting(false);
      }
  };
}

const updatedoc = async(e)=>{
  e.preventDefault();
    const errors = {};
    if (!selectedproduct) {
      errors.product = 'Product is required';
    }
    if (!name) {
      errors.name = 'Document Name is required';
    }
    if (!selectedorgtype) {
      errors.orgtype = 'Org Type is required';
    }
    if (!selectedcategory) {
      errors.category = 'Category is required';
    }
  setValidationErrors(errors);
 if (Object.keys(errors).length === 0) {
  const formData = new FormData()
  formData.append('category',selectedcategory);
  formData.append('document_name',name);
  formData.append('is_required',checked? 1 : 0);
  formData.append('emp_id',emp_id);
  formData.append('product',selectedproduct)
  const selectedorgnization = Array.isArray(selectedorgtype)
    ? selectedorgtype
    : [selectedorgtype];

    selectedorgnization.forEach((Id) => {
    formData.append('org_type[]',Id);
  });

  const selectedcategory_ids = Array.isArray(selectedcategory)
  ? selectedcategory
  : [selectedcategory];

  selectedcategory_ids.forEach((Id) => {
  formData.append('category_array[]',Id);
});

    //setIsSubmitting(true);

    try {
     const response =  await dashboardApi.post(`sales-document-update/${id}`, formData);
      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        fetchDocument();
        closemodel();

      }
      else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the enquiry',
      });
    }
 }
}
console.log(validationErrors);


//console.log(raiselist);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <Link className=' btn-sm btn btn-primary mb-2 float-end' onClick={openpopup}>
                    Add Document Type
                </Link>
                 {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>product</td>
                  <td>Category</td>
                  <td>Org Type</td>
                  <td>Document</td>
                  <td>Is Required</td>
                  <td>Status</td>
                  <td>Action</td>
               </tr>
               {documentlist.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.product}</td>
                  <td>{row.category}</td>
                  <td>{row.org_type}</td>
                  <td>{row.document_name}</td>
                  <td>{row.is_required}</td>
                  <td><button
                  type="button"
                  className={` btn-sm btn btn-${row.status === 1 ? 'success' : 'danger'}`}
                  onClick={() => toggleStatus(row.id)}
                  >
                  {row.status === 1 ? 'Active' : 'Inactive'}
                 </button></td>
                 <td>
                      <button className="btn btn-info btn-sm"onClick={() => editform(row.id)}>Edit</button>
                   </td>
               </tr>
              ))}
            </table>
             )}
                
              </div>
            </Card>
            <Dialog
        open={openModal}
        fullWidth
        maxWidth = 'md'
        PaperProps={{ style: {padding: '20px', height:'600px'} }}
      >
        <DialogTitle>Document Type</DialogTitle>
        <DialogContent>
          <form>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <label>Org Type:</label>
                  <select
                    multiple
                    class="form-select"
                    classname="form-control"
                    value={selectedorgtype}
                    onChange={handleorgtype}
                    aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                  >
                    <option value="">Select Org Type</option>
                    {org_type.map(org => (
                      <option key={org.id} value={org.id}>
                        {org.org_type}
                      </option>
                    ))}
                   
                  </select>
                  {validationErrors.orgtype && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.orgtype}</div>
                             )}
                </div>

                
                 <div className="col-md-6">
                  <label>Product</label>
                  <select
                    class="form-select"
                    classname="form-control"
                    value={selectedproduct}
                    onChange={(e) => setselectedproduct(e.target.value)}
                    //aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                  >
                    <option value="">Select product</option>
                    {productlist.map(product => (
                      <option key={product.id} value={product.id}>
                        {product.product_name}
                      </option>
                    ))}
                   
                  </select>
                  {validationErrors.product && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.product}</div>
                             )}
                </div>
            </div>

                {/* <div className="col-md-6">
                  <label>Product Category/Service :</label>
                  <select
                    class="form-select"
                    classname="form-control"
                    value={selectedcategory}
                    onChange={(e) => setselectedcategory(e.target.value)}
                    //aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                  >
                    <option value="">Select Category</option>
                    {category.map(cat => (
                      <option key={cat.id} value={cat.id}>
                        {cat.category_name}
                      </option>
                    ))}
                   
                  </select>
                </div>
            </div> */}
            <div className='row'>
            <div className="col-md-6">
                  <label>Product Category/Service</label>
                  <select
                    multiple
                    class="form-select"
                    classname="form-control"
                    value={selectedcategory}
                    onChange={handlecategory}
                    aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                  >
                    <option value="">Select Service</option>
                    {servicedetailslist.map(org => (
                      <option key={org.id} value={org.id}>
                        {org.name}
                      </option>
                    ))}
                   
                  </select>
                </div>


                <div className='col-md-6'>
                    <label>Document Type Name</label>
                    <input type="text"
                    className='form-control'
                    value = {name}
                    onChange={(event)=>{
                      setname(event.target.value)}}
                    placeholder="Enter Document Name"
                     />
                      {validationErrors.name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.name}</div>
                             )}

                </div>
                </div>
                <div className='row'>
                <div className='col-md-4'>
                <label>Is Required</label>
                          <input
                            type="checkbox"
                              className="form-control"
                              checked={checked}
                              onChange={(e) => setchecked(e.target.checked)}
                             //checked={checked}
                             //onChange={(e) => setchecked(e.target.checked)}
                           />

                </div>

            </div>
           
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={closemodel} >
            Close
          </button>
          <button className="btn btn-info" onClick={savedocument} >
            Save
          </button>
        </DialogActions>
       </Dialog>

       <Dialog
        open={editopenmodel}
        fullWidth
        maxWidth = 'md'
        PaperProps={{ style: {padding: '20px', height:'600px'} }}
      >
        <DialogTitle>Document Type</DialogTitle>
        <DialogContent>
          <form>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <label>Org Type:</label>
                  <select
                    multiple
                    class="form-select"
                    classname="form-control"
                    value={selectedorgtype}
                    onChange={handleorgtype}
                    aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                  >
                    <option value="">Select Org Type</option>
                    {org_type.map(org => (
                      <option key={org.id} value={org.id}>
                        {org.org_type}
                      </option>
                    ))}
                   
                  </select>
                </div>

                
                 <div className="col-md-6">
                  <label>Product</label>
                  <select
                    class="form-select"
                    classname="form-control"
                    value={selectedproduct}
                    onChange={(e) => setselectedproduct(e.target.value)}
                    //aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                  >
                    <option value="">Select product</option>
                    {productlist.map(product => (
                      <option key={product.id} value={product.id}>
                        {product.product_name}
                      </option>
                    ))}
                   
                  </select>
                  {validationErrors.product && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.product}</div>
                             )}
                </div>
            </div>

                {/* <div className="col-md-6">
                  <label>Product Category/Service :</label>
                  <select
                    class="form-select"
                    classname="form-control"
                    value={selectedcategory}
                    onChange={(e) => setselectedcategory(e.target.value)}
                    //aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                  >
                    <option value="">Select Category</option>
                    {category.map(cat => (
                      <option key={cat.id} value={cat.id}>
                        {cat.category_name}
                      </option>
                    ))}
                   
                  </select>
                </div>
            </div> */}
            <div className='row'>
            <div className="col-md-6">
                  <label>Product Category/Service</label>
                  <select
                    multiple
                    class="form-select"
                    classname="form-control"
                    value={selectedcategory}
                    onChange={handlecategory}
                    aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                  >
                    <option value="">Select Service</option>
                    {servicedetailslist.map(org => (
                      <option key={org.id} value={org.id}>
                        {org.name}
                      </option>
                    ))}
                   
                  </select>
                </div>


                <div className='col-md-6'>
                    <label>Document Type Name</label>
                    <input type="text"
                    className='form-control'
                    value = {name}
                    onChange={(event)=>{
                      setname(event.target.value)}}
                    placeholder="Enter Document Name"
                     />
                     {validationErrors.name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.name}</div>
                             )}


                </div>
                </div>
                <div className='row'>
                <div className='col-md-4'>
                <label>Is Required</label>
                          <input
                            type="checkbox"
                              className="form-control"
                              checked={checked}
                              onChange={(e) => setchecked(e.target.checked)}
                             //checked={checked}
                             //onChange={(e) => setchecked(e.target.checked)}
                           />

                </div>

            </div>
           
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={closemodel} >
            Close
          </button>
          <button className="btn btn-info" onClick={updatedoc} >
            Update
          </button>
        </DialogActions>
       </Dialog>

          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default SalesDoc;