import Icon from "@mui/material/Icon";
import SignIn from "layouts/authentication/sign-in";
import VendorList from "layouts/vendor";
import AddVendor from "layouts/vendor/add-vendor";
import EditVendor from "layouts/vendor/edit-vendor";
import CategoryList from "layouts/stock_details/category";
import AddCategory from "layouts/stock_details/category/create-category";
import EditCategory from "layouts/stock_details/category/edit-category";
import StockList from "layouts/stock_details/stock";
import AddStock from "layouts/stock_details/stock/add_stock";
import EditStock from "layouts/stock_details/stock/edit_stock";
import AssignStock  from "layouts/stock_details/assign/assign_stock.js"
import AssignList from "layouts/stock_details/assign";
import EditAssignList from "layouts/stock_details/assign/edit_assign_stock";
import EmpStockList from "layouts/stock_details/emp_stock.js";
import ReturnList from "layouts/stock_details/return_stock.js";
import RaiseAssetRequest from "layouts/stock_details/raise-asset-request";
import AddSalesDocument from "layouts/sales_document/add_sales_document.js";
import DocumentList from "layouts/sales_document";
import EditSalesDocument from "layouts/sales_document/edit_sales_document.js";
import  secureLocalStorage  from  "react-secure-storage";
import StockRepair from "layouts/stock_details/stock_repair";
import StockHistory from "layouts/stock_details/stock_history";
import EmpStockHistory from "layouts/stock_details/emp_stock_history";
const emp_id = secureLocalStorage.getItem('emp_id');
const token = secureLocalStorage.getItem('token');
//const permission = localStorage.getItem('permission');

const Stockroutes = [
  {
    route: "/add-sales-document",
    component: (token!=null) ? <AddSalesDocument/> : <SignIn/>,

  },
  {
    route: "/edit-sales-document/:id",
    component: (token!=null) ? <EditSalesDocument/> : <SignIn/>,

  },
  {
    route: "/document-list",
    component: (token!=null) ? <DocumentList/> : <SignIn/>,

  },
   {
    route: "/vendor-list",
    component: (token!=null) ? <VendorList/> : <SignIn/>,
   },
   {
    route: "/add-vendor",
    component: (token!=null) ? <AddVendor/> : <SignIn/>,
   },
    {
    route: "/edit-vendor/:id",
    component: (token!=null) ? <EditVendor/> : <SignIn/>,
   },
   {
    route: "/category-list",
    component: (token!=null) ? <CategoryList/> : <SignIn/>,
   },
   {
    route: "/add-category",
    component: (token!=null) ? <AddCategory/> : <SignIn/>,
   },
   {
    route: "/edit-category/:id",
    component: (token!=null) ? <EditCategory/> : <SignIn/>,
   },
   {
      route: "/stock-list",
      component: (token!=null) ? <StockList/> : <SignIn/>,
   },
   {
    route: "/add-stock",
    component: (token!=null) ? <AddStock/> : <SignIn/>,
  },
  {
    route: "/edit-stock/:id",
    component: (token!=null) ? <EditStock/> : <SignIn/>,
  },
  {
    route: "/assign-stock",
    component: (token!=null) ? <AssignStock/> : <SignIn/>,
  },
  {
    route: "/assign-list",
    component: (token!=null) ? <AssignList/> : <SignIn/>,
  },
  {
    route: "/edit-assign-stock/:id",
    component: (token!=null) ? <EditAssignList/> : <SignIn/>,
  },
  {
    route: "/emp-stock-list",
    component: (token!=null) ? <EmpStockList/> : <SignIn/>,
  },

  {
    route: "/emp-stock/:EmployeeId",
    component: (token!=null) ? <EmpStockList/> : <SignIn/>,
  },
  {
     route: "/return-list",
    component: (token!=null) ? <ReturnList/> : <SignIn/>,
  },
  {
    route:"/raise-asset-request",
    component:(token!=null)?<RaiseAssetRequest/>:<SignIn/>,
  },
  {
    route:"/stock-repair",
    component:(token!=null)?<StockRepair/>:<SignIn/>,

  },
  {
    route:"/stock-history/:id",
    component:(token!=null)?<StockHistory/>:<SignIn/>,

  },
  {
    route:"/emp-stock-history/:id",
    component:(token!=null)?<EmpStockHistory/>:<SignIn/>,

  },
  {
    type: "collapse",
    name: "Stock Details",
    key: "Stock Details",
    icon: <Icon fontSize="small">inventory_2</Icon>,
    route: "/vendor-list",
    component: (token!=null) ? <VendorList/> : <SignIn/>,
    nested:[],
  },
    
 

];

export default Stockroutes;