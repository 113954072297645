import axios from 'axios';
import  secureLocalStorage  from  "react-secure-storage";

const dashboardApi = axios.create({
  //baseURL: 'http://localhost:8000/api/',
  baseURL: 'https://apiworkforce.r-ims.com/api/',
});

dashboardApi.interceptors.request.use((config) => {
  const token = secureLocalStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

dashboardApi.interceptors.response.use(
  (response) => {
    if (response.status === 204) {
      return Promise.reject('No Content');
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      secureLocalStorage.removeItem('token');
      window.location.href = '/sign-in';
    }
    return Promise.reject(error);
  }
);

export default dashboardApi;
