import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

// react-router components
import { useLocation, Link } from "react-router-dom";
import './notificationstyle.css';

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
//import Card from '@mui/material/Card';

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Swal from 'sweetalert2'

// Material Dashboard 2 React example components
import Breadcrumbs from "theme_layout/Breadcrumbs";
//import NotificationItem from "theme_layout/Items/NotificationItem";
import dashboardApi from "layouts/common_components/apibase_url";
import SessionOut from "layouts/common_components/sessionout";
import SessionLogout from "layouts/common_components/sessionlogout";
//import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer } from "react-toastify";
//import LiveNotification from "layouts/live_notification";
//import TicketNotification from "layouts/ticket_notification";
//import AsignNotification  from "layouts/ticket_notification/assign_ticket";
import secureLocalStorage from "react-secure-storage";
import TicketEvent from 'layouts/event_notification/ticketevent';
import Pusher from "pusher-js";
import WfhNotification from 'layouts/event_notification/wfhnotification';
import LeaveNotification from 'layouts/event_notification/leavenotification';
import AcceptEvent from 'layouts/event_notification/acceptevent.js';
import ShowEmpNotification from 'layouts/event_notification/show_emp_notification';
import './toggle_button.css';
//import getDrawerOpenStyles from 'theme_layout/Sidenav/SidenavRoot';
import { getDrawerOpenStyles } from 'theme_layout/Sidenav/SidenavRoot';

//import 'react-toastify/dist/ReactToastify.min.css';
//import socket from './soket.js';

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "theme_layout/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";


/*const Notification = ({ message }) => {
  return (
    <Card className="notification-card">
      <MDBox p={2}>
        <MDTypography variant="body1">{message}</MDTypography>
      </MDBox>
    </Card>
  );
};*/

function DashboardNavbar({ absolute, light, isMini }) {
  const token = secureLocalStorage.getItem('token');
  //const socket = io('http://localhost:8000');
  const emp_id = secureLocalStorage.getItem('emp_id');
  const dept_id = secureLocalStorage.getItem('department_id');

  //alert(emp_id);
  const employeeId = secureLocalStorage.getItem('emp_id');
  //const department = secureLocalStorage.getItem('department_id');
  const [notificationcount, setnotificationcount] = useState([]);
  const [ticketcount, setticketcount] = useState([]);
  const navigate = useNavigate();
  const [navbarType, setNavbarType] = useState();
  //const [livenotification,setlivenotification] = useState([]);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [empdata, getempdata] = useState({});
  const [isToggled, setIsToggled] = useState(false);
  const [check_current_break_status, set_current_break_status] = useState(0);
  const [start_time, setstart_time] = useState('');
  const [end_time, setend_time] = useState('');
  const [break_status, setbreak_status] = useState();
  const [idle_time_total, set_idle_time_total] = useState([]);
  const [break_time_total, set_break_time_total] = useState([]);
  const [sales_missing_followup, set_sales_missing_followup] = useState();
  const [sales_missing_kra_kpi, set_sales_missing_kra_kpi] = useState();
  const [percent, setpercent] = useState();

  let attendance_status = '';
  //secureLocalStorage.setItem('current_url', window.location.href);
  let skip_url = 'http://192.168.1.253:3000/generate-ticket';
  const new_url = window.location.href;
  if (new_url != skip_url) {
    secureLocalStorage.setItem('current_url', window.location.href);
  }
  const [notificationDisplayed, setNotificationDisplayed] = useState(false);
  const [latestEnquiry, setLatestEnquiry] = useState(null);
  const route = useLocation().pathname.split("/").slice(1);
  const viewnotification = async () => {
    try {
      const updateEndpoint = `notification-seen-status/${emp_id}`;
      const updateResponse = await dashboardApi.get(updateEndpoint);

      if (updateResponse.data.status === 200) {
        //toast.dismiss();
        navigate('/notifications');
      } else {
        console.error('Error in update status:');
      }
    } catch (error) {
      console.error('Error in update status:', error);
    }


  }
  useEffect(() => {
    count_sales_followup();
    kra_and_kpi_percent();
    

  }, [])

  if (percent < 20) {
    attendance_status = 'Absent';
  }
  else if (percent >= 20 && percent < 70) {
    attendance_status = 'Half Day';
  }
  if (percent >= 70) {
    attendance_status = 'Present';
  }
  // useEffect(() => {
  //    // Initialize Pusher with your app key and cluster
  //    const pusher = new Pusher("0a6e32a7810cbb7e20d7", {
  //      cluster: "ap2",
  //      encrypted: true,
  //    });

  //    // Subscribe to the unique channel for the specific employee
  //    const channel = pusher.subscribe(`employee-channel-${employeeId}`);

  //    // Bind to the event you want to listen to
  //    channel.bind("my-eventhhh", (data) => {
  //       const audio = new Audio('http://localhost:8000/profile_picture/mixkit-bell-notification-933.wav'); // Adjust the path to your sound file
  //       audio.play();
  //      console.log("Received notification: ", data.message);
  //      //alert(JSON.stringify(data.message));
  //    });

  //    // Cleanup on component unmount
  //    return () => {
  //      pusher.unsubscribe(`employee-channel-${employeeId}`);
  //      pusher.disconnect();
  //    };
  //  }, [employeeId]);

  //  const viewticket = async () => {
  //   try {
  //     const updateEndpoint = `update-seen-status/${emp_id}`;
  //     const updateResponse = await dashboardApi.get(updateEndpoint);

  //     if (updateResponse.data.status === 200) {
  //     // alert();
  //        //toast.dismiss();
  //       //navigate('/notifications');
  //     } else {
  //       console.error('Error in update status:');
  //     }
  //   } catch (error) {
  //     console.error('Error in update status:', error);
  //   }


  // }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = `login-hours/${emp_id}`;
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          getempdata(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    fetch_current_break_status();

  }, [emp_id]);

  const count_sales_followup = async () => {
    try {
      const response = await dashboardApi.get('count-sales-followup', {
        params: {
          emp_id: emp_id,
        },
      });
      if (response.data.status === 200) {
        response.data.followup_data;
        set_sales_missing_followup(response.data.missing_followup);
        set_sales_missing_kra_kpi(response.data.missing_kra_kpi)
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  //console.log('llll', sales_missing_kra_kpi);


  const handlebreaktime = async (e) => {
    e.preventDefault();

    let newBreakStatus = check_current_break_status === 1 ? 0 : 1;
    let newStartTime = check_current_break_status === 1 ? '' : new Date().toLocaleTimeString();
    let newEndTime = check_current_break_status === 1 ? new Date().toLocaleTimeString() : '';

    setbreak_status(newBreakStatus);
    setstart_time(newStartTime);
    setend_time(newEndTime);

    const formData = new FormData();
    formData.append('emp_id', emp_id);
    formData.append('start_time', newStartTime);
    formData.append('end_time', newEndTime);
    formData.append('status', newBreakStatus);

    try {
      const response = await dashboardApi.post('store-break-time', formData);
      if (response.status === 200) {
        fetch_current_break_status();
      }
    } catch (error) {
      console.error('Error updating break time:', error);
    }
  };

  const kra_and_kpi_percent = async () => {
    try {
      const response = await dashboardApi.get('show-kra-and-kpi-sales-dashboard', {
        params: {
          emp_id: emp_id,
        },
      });
      if (response.data.status === 200) {
        const responseData = response.data.data;
        setpercent(responseData);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const fetch_current_break_status = async () => {
    try {
      const endpoint = `get-break-time-status/${emp_id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.status === 200) {
        set_current_break_status(response.data.break_status);
        set_break_time_total(response.data.break_time);
        set_idle_time_total(response.data.idle_count);
      }
    } catch (error) {
      console.error('Error fetching break status:', error);
    }
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      if (dept_id === 3 || dept_id === 9) {
        await inactive_packages_notifications(emp_id);
      }
    };

    fetchNotifications();
  }, [emp_id, dept_id]);

  const inactive_packages_notifications = async (emp_id) => {
    try {
      const response = await dashboardApi.get('package-inactive-notifications', {
        params: {
          emp_id: emp_id,
        },
      });
      //console.log(response.data);
    } catch (error) {
      console.error('Error fetching inactive client notifications:', error);
    }
  };
  







  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  const logout = async (e) => {
    e.preventDefault();
    if (dept_id == 3) {
      const isConfirm = await Swal.fire({
        title: 'Are you sure to Logout?',
        html: `Today Missing Kra Kpi (Payment Followup):${sales_missing_kra_kpi} <br>Today Missing Followup: ${sales_missing_followup}<br>Attendance Status:${attendance_status}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
      }).then((result) => {
        return result.isConfirmed
      });

      if (!isConfirm) {
        return;
      }
    }
    try {

      const endpoint = `logout/${emp_id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === '200') {
        secureLocalStorage.removeItem('token');
        navigate("/sign-in");
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  }
  // useEffect(() => {
  //    const fetchData = async () => {
  //     // await fetchNotifications();
  //      await fetchnotification();
  //      await fetchticket();
  //    };

  //    fetchData();

  //    const intervalId = setInterval(() => {
  //      fetchData();
  //    }, 60000);

  //    return () => clearInterval(intervalId);
  //  }, []);
  const fetchnotification = async () => {
    try {
      const endpoint = `notification-count/${emp_id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        //console.log(response);
        setnotificationcount(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching  data:', error);
    }
  }

  // const fetchticket = async () => {
  //     try {
  //       const endpoint = `ticket-raise-notification/${emp_id}`;
  //       const response = await dashboardApi.get(endpoint);

  //       if (response.data.status === 200) {
  //        //console.log(response);
  //         setticketcount(response.data.count);
  //       } 
  //     } catch (error) {
  //       console.error('Error fetching  data:', error);
  //     }
  // }
  // alert(notificationcount);


  /*const fetchData = async () => {
      try {
        const endpoint = `birthday-notification/${emp_id}`;
        const response = await dashboardApi.get(endpoint);
  
                   if (response.data.isBirthday) {
                      toast.success('🎉 Happy Birthday'+' '+response.data.name, {
                          position: "top-right",
                          autoClose: 5000, // 5 seconds
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                      });
                  } 
               } catch (error) {
                 console.error('Error fetching data:');
                 }
          };*/
  //console.log(livenotification.message);


  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

  

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar className="yyyyy"
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar className="d-flex flex-row" sx={(theme) => navbarContainer(theme)}>
      <MDBox className="d-sm-block d-none" color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
      <span style={{fontWeight:'bold'}}>{empdata.first_name} {empdata.last_name}({empdata.emp_id})</span>
          
         </MDBox>
        {isMini ? null : (
          <MDBox  sx={(theme) => navbarRow(theme, { isMini })}>
             <IconButton
              size="small"
              disableRipple
              color="inherit"
              sx={navbarMobileMenu}
              onClick={handleMiniSidenav}
            >
              <Icon sx={iconsStyle} fontSize="medium">
                {miniSidenav ? "menu_open" : "menu"}
              </Icon>
            </IconButton>
            <SessionOut />
            <SessionLogout />
            <TicketEvent />
            <WfhNotification />
            <LeaveNotification />
            <AcceptEvent />
            <ShowEmpNotification />
            <h6 className="m-0 px-3 d-sm-inline d-none">Login Hours : {empdata.total_login_times}</h6>
            <div className="px-3 d-sm-flex d-none align-items-center" >
              <h6 className="m-0" >Break Status</h6>
              <button onClick={handlebreaktime} className={`toggle-button ml-1 rounded ${check_current_break_status === 1 ? 'on' : 'off'}`}>
                {check_current_break_status === 1 ? 'ON' : 'OFF'}
              </button>
            </div>
            <div className="px-3 d-flex align-items-center" >
              <button onClick={logout} className="btn btn-info btn-sm ml-1">logout</button>
            </div>
            <MDBox color={light ? "white" : "inherit"}>
              <Link className="d-flex align-items-center" onClick={viewnotification}>
                <Icon sx={iconsStyle}>notifications</Icon>
                {notificationcount > 0 && (
                  <span className="notification-count">{notificationcount}</span>
                )}
              </Link>
              {renderMenu()}
            </MDBox>
            
          </MDBox>
        )}
      </Toolbar>

    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};
<ToastContainer />

export default DashboardNavbar;