import React, { useState } from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";
import secureLocalStorage from "react-secure-storage";
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress for the loader

function Basic() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const [permission, Setpermission] = useState();
  const [loading, setLoading] = useState(false); // State for loading

  const handleLogin = async (e) => {
    e.preventDefault();
    const errors = {};

    if (!email) {
      errors.email = 'Email is required';
    }
    if (!password) {
      errors.password = 'Password is required';
    }
    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
      setLoading(true); // Show loader
      secureLocalStorage.removeItem('department');
      const formData = new FormData();
      formData.append('email', email);
      formData.append('password', password);

      try {
        const response = await axios.post('https://apiworkforce.r-ims.com/api/login', formData);

        if (response.data.status === 200) {
          const token = response.data.token;
          const department = response.data.data.department;
          const email = response.data.data.email;
          const emp_id = response.data.data.emp_id;
          const branch = response.data.data.branch;
          const ip = response.data.data.ip;
          const last_password_date = response.data.data.last_password_update_days;
          const department_id = response.data.data.department_id;
          const designation_id = response.data.data.desi_id;
          secureLocalStorage.setItem('token', token);
          secureLocalStorage.setItem('department', department);
          secureLocalStorage.setItem('email', email);
          secureLocalStorage.setItem('emp_id', emp_id);
          secureLocalStorage.setItem('branch', branch);
          secureLocalStorage.setItem('department_id', department_id);
          secureLocalStorage.setItem('ip', ip);
          secureLocalStorage.setItem('designation_id',designation_id);
          
          const employee_id = secureLocalStorage.getItem('emp_id');
          const fetchData = async () => {
            try {
              const endpoint = `fetch-emp-permission/${employee_id}`;
              const response = await dashboardApi.get(endpoint);
              if (response.data.status === 200) {
                Setpermission(response.data.data);
                secureLocalStorage.setItem('permission', JSON.stringify(response.data.data));
              } else {
                console.error('Error fetching data:', response.data.message);
              }
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };

          await fetchData();

          if (last_password_date === 'not found' || last_password_date > 30) {
            window.location.href = '/change-password';
          } else {
            window.location.href = '/dashboard';
          }
        } else if (response.data.status === 400) {
          setValidationErrors(response.data.messages || {});
          setLoginErrorMessage('Email or password is incorrect');
        } else {
          Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
          });
          navigate('/sign-in');
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          text: 'An error occurred while logging in',
        });
      } finally {
        setLoading(false); // Hide loader
      }
    }
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            RIMS
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <form onSubmit={handleLogin}>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email:
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {validationErrors.email && (
                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.email}</div>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password:
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {validationErrors.password && (
                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.password}</div>
              )}
            </div>
            <div className="mb-3">
              {loading ? (
                <div className="text-center">
                  <CircularProgress /> {/* Display the loader */}
                </div>
              ) : (
                <MDButton variant="gradient" color="info" type="submit" block>
                  Sign In
                </MDButton>
              )}
            </div>
          </form>
          <div className="text-center">
            {loginErrorMessage && (
              <p className="text-danger" style={{ fontSize: '18px' }}>{loginErrorMessage}</p>
            )}
            {/* <p>
              Don't have an account?{" "}
              <Link to="/authentication/sign-up" className="text-info font-weight-medium">
                Sign up
              </Link>
            </p> */}
          </div>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
