import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import SidenavCollapse from "theme_layout/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "theme_layout/Sidenav/SidenavRoot";
import sidenavLogoLabel from "theme_layout/Sidenav/styles/sidenav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import secureLocalStorage from "react-secure-storage";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const [collapsedItems, setCollapsedItems] = useState({});
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");
  const token = secureLocalStorage.getItem('token');

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    window.addEventListener("resize", handleMiniSidenav);

    handleMiniSidenav();

    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const renderRoutes = routes.map(({ type, name, icon, title, key, href, route, nested }) => {
    if (token == null) {
      return false;
    }
    let returnValue;
    const toggleCollapse = () => {
      setCollapsedItems((prev) => ({
        ...prev,
        [key]: !prev[key],
      }));
    };
    const isItemCollapsed = collapsedItems[key];
    const hasNestedLinks = nested && nested.length > 0;

    if (type === "collapse") {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={!hasNestedLinks}
          />
        </Link>
      ) : (
        <div className="mb-2">
          <NavLink key={key} to={route}>
            <p className="mt-2 mb-0 px-2" >
              <a
                className="btn btn-info border-0 justify-content-between p-2"
                style={{
                  textDecoration: "none",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "0px"
                }}
                role="button"
                onClick={toggleCollapse}
                aria-expanded={!isItemCollapsed}
                aria-controls={`collapse_${key}`}
              >
                <span className="d-flex align-items-center px-2">{icon}<span className="pl-3 ">{name}</span>
                </span>
                {hasNestedLinks && (
                  <FontAwesomeIcon icon={isItemCollapsed ? faChevronDown : faChevronUp} />
                )}
              </a>
            </p>
            {nested.length > 0 && (
              <div className={`collapse px-2 ${isItemCollapsed ? 'show' : ''}`} id={`collapse_${key}`}>
                <div className="card p-0 rounded-0">
                  {nested.map((s) => (
                    <NavLink to={s.route} key={s.key}>
                      <a
                        className="p-2"
                        style={{
                          textDecoration: "none",
                          color: "black",
                          display: "flex",
                          alignItems: "center",
                        }}
                        role="button"
                      >
                        <span className="d-flex align-items-center">{s.icon}  <span className="pl-2 small">{s.name}</span></span>
                      </a>
                    </NavLink>
                  ))}
                </div>
              </div>
            )}
          </NavLink>
        </div>
      );
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }

    return returnValue;
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox  pt={3} pb={1} px={4}>
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={4.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon className="position-absolute text-white" sx={{ fontWeight: "bold",right:"10px",top:"10px" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} className="d-flex justify-content-center">
          {brand && <MDBox component="img" className="mx-auto" src={brand} alt="Brand" width="130px" height="50px" />}
          <MDBox className="m-0 "
            width={!brandName && "100"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;