import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Button from "@mui/material/Button";
import dashboardApi from 'layouts/common_components/apibase_url';
//import './EmpRoster.css'; // Import the CSS file

function EmpRoster() {
    const [employees, setEmployees] = useState([]);
    const [datesInMonth, setDatesInMonth] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const daysPerPage = 15;

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await dashboardApi.get('show-emp-roster');
                setEmployees(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching employee data:', error);
                setLoading(false);
            }
        };

        const getCurrentMonthDates = () => {
            const today = new Date();
            const year = today.getFullYear();
            const month = today.getMonth();
            const daysInMonth = new Date(year, month + 1, 0).getDate();
            const dates = [];

            for (let day = 1; day <= daysInMonth; day++) {
                dates.push(`${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`);
            }

            setDatesInMonth(dates);
        };

        getCurrentMonthDates();
        fetchEmployees();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    const totalPages = Math.ceil(datesInMonth.length / daysPerPage);
    const currentDates = datesInMonth.slice(currentPage * daysPerPage, (currentPage + 1) * daysPerPage);

    const handleNext = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card>
                            <div className="card-body">
                                <h6>Employee Roster for {new Date().toLocaleString('default', { month: 'long' })}</h6>
                                <div className="table-container">
                                    <table className="roster-table table-responsive">
                                        <thead>
                                            <tr>
                                                <td className="fixed-column">Name</td>
                                                {currentDates.map(date => (
                                                    <td key={date}>{date}</td>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {employees.map(employee => (
                                                <tr key={employee.emp_name}>
                                                    <td className="fixed-column">{employee.emp_name}</td>
                                                    {currentDates.map(date => {
                                                        const isPresent = employee.attendance_dates && employee.attendance_dates.includes(date);
                                                        const isLeave = employee.leave_dates && employee.leave_dates.includes(date);
                                                        return (
                                                            <td key={date} className={isPresent ? 'present' : isLeave ? 'leave' : ''}>
                                                                {isPresent ? 'Week Off' : isLeave ? 'Leave' : ''}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{ marginTop: '20px' }}>
                                    <Button 
                                        onClick={handlePrevious} 
                                        disabled={currentPage === 0} 
                                        variant="contained" 
                                        color="primary"
                                    >
                                        Previous
                                    </Button>
                                    <Button 
                                        onClick={handleNext} 
                                        disabled={currentPage >= totalPages - 1} 
                                        variant="contained" 
                                        color="primary"
                                        style={{ marginLeft: '10px' }}
                                    >
                                        Next
                                    </Button>
                                </div>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}

export default EmpRoster;
