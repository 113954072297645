import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link,useParams } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import docurl from 'layouts/common_components/doc_base_url'; 
import {useNavigate } from 'react-router-dom';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AdminDetailsHeader from 'layouts/common_components/admin_details_header';
import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import  secureLocalStorage  from  "react-secure-storage";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import LoadingModal from 'layouts/loaderModal/LoadingModal';

function Request_details() 
{
  const navigate = useNavigate();
  const { clientId } = useParams();
  const emp_id = localStorage.getItem('emp_id');
  const token = localStorage.getItem('token');
  const department = localStorage.getItem('department');
  
  //raise request
  const [validationErrors, setValidationErrors] = useState({});
  const [raiseRequest,setRaiseRequestDetails] = useState([]);
  const [actionModal,setActionModal]=useState(false);
  const [number, setNumber] = useState(1);
  const [postPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [requestDetails, setRequestDetails]=useState('');
  const [packageRemarks, setPackageRemarks]=useState('');
  const [packageReturnMessage, setPackageReturnMessage]=useState('');
  const [selectedRequestId, setSelectedRequestId]=useState('');
  const [ticketStatus, setTicketStatus]=useState([]);

  const [selectedTicketStatus, setSelectedTicketStatus] = useState(''); 
  const [requestRemarks, setRequestRemarks]=useState('');
  

  const closeRequestModal = ()=>{
    setActionModal(false);
    setPackageRemarks('');
    setPackageReturnMessage('');
    setSelectedRequestId('');
    setSelectedTicketStatus('');
    setRequestRemarks('');
  }

    const handlePageClick = (selected) => {
      setCurrentPage(selected.selected);
    };

    useEffect(() => {
      getRaiseRequestDetails();
      getTicketStatus();
    },[]);

    const openModalAction = async(id) =>
    {
      setActionModal(true);
      setSelectedRequestId(id);
      getReguestDetailsById(id);
    }

    const getTicketStatus = async()=>{
      try {
        const response = await dashboardApi.get(`get-ticket-status-details`,{
          params: {
            emp_id: emp_id,
         }
        });
        if(response.data.status === 200)
        {
          setTicketStatus(response.data.data);
        }
        else 
        {
          console.error('Error fetching countries:', response.data.message);
        }
      }
      catch (error) {
        console.error('Error fetching countries:', error);
      }
    }


    const getReguestDetailsById =  async (id) => 
    {
      try {
        const response = await dashboardApi.get(`get-request-details-by-id`,{
          params: {
            emp_id: emp_id,
            id: id,
          }
        });
        if(response.data.status === 200)
        {
          setRequestDetails(response.data.data);
        }
        else 
        {
          console.error('Error fetching countries:', response.data.message);
        }
      }
      catch (error) {
        console.error('Error fetching countries:', error);
      }

    }


   

    const getRaiseRequestDetails = async () => 
    {
      try {
        const response = await dashboardApi.get(`get-new-raise-request-details`,{
          params: {
            emp_id: emp_id,
            per_page: postPerPage,
            page: currentPage + 1,
          }
        });
        if(response.data.status === 200)
        {
          const responseData = response.data.data;
          const clientData = responseData.data || responseData;
          setNumber(responseData.last_page);
          setRaiseRequestDetails(clientData);
        }
        else 
        {
          console.error('Error fetching countries:', response.data.message);
        }
      }
      catch (error) {
        console.error('Error fetching countries:', error);
      }
    }

    const packageActivateDeactvate = async(client_id,reqId,packageId,status)=>
    {
      const errors = {};
      if (!packageRemarks) {
        errors.packageRemarks = 'Remarks is required.';
      }

      setValidationErrors(errors);
      if (Object.keys(errors).length > 0) {
        return;
      }
      try {
        const response = await dashboardApi.get(`change-package-status-by-admin`,{
          params: {
            emp_id: emp_id,
            reqId:reqId,
            packageId: packageId,
            status: status,
            clientId:client_id,
            packageRemarks:packageRemarks
          }
        });
        if(response.data.status === 200)
        {
          getReguestDetailsById(reqId);
          setPackageReturnMessage(response.data.message);
          
        }
        else 
        {
          setPackageReturnMessage(response.data.message);
        }
      }
      catch (error) {
        setPackageReturnMessage('Error fetching countries:' + error);
      }
    }

  
    const updateRequestStatus = async (regId) => {
      const errors = {};
    
      if (!selectedTicketStatus) {
        errors.selectedTicketStatus = 'Ticket status is required.';
      }
    
      if (!requestRemarks) {
        errors.requestRemarks = 'Request remarks are required.';
      }
    
      setValidationErrors(errors);
    
      if (Object.keys(errors).length > 0) {
        return;
      }
    
      try {
        const response = await dashboardApi.get('update-request-status', {
          params: {
            emp_id: emp_id,
            reqId: regId,
            ticketStatusId: selectedTicketStatus,
            requestRemarks: requestRemarks,
            followupBy: 3,
          },
        });
    
        if (response.data.status === 200) {
          getRaiseRequestDetails();
          setActionModal(false);
          closeRequestModal();
          Swal.fire({
            icon: 'success',
            text: response.data.message,
          });
          
        } else {
          Swal.fire({
            icon: 'error',
            text: response.data.message,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          text: `Error updating request status: ${error.message}`,
        });
      }
    };
     
    return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
                <div className='card-body' id="request_details" style={{ overflowY: "hidden" }}>
                  <LoadingModal isLoading={isLoading} />
                  <table className="table table-bordered table-hovered">
                    <tr style={{ fontWeight: '700' }}>
                      <td>S.No</td>
                      <td>Company Name</td>
                      <td>Service</td>
                      <td>Req For</td>
                      <td>Req Description</td>
                      <td>Status</td>
                      <td>Executive</td>
                      <td>Assign To</td>
                      <td>Created By</td>
                      <td>Created Date</td>
                      <td>Action</td>
                    </tr>

                    {raiseRequest && raiseRequest.map((rr, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{rr.business_name}</td>
                        <td>{rr.service_title}</td>
                        <td>{rr.req_title}</td>
                        <td>{rr.complaint}</td>
                        <td>{rr.ticket_title}</td>
                        <td>{rr.emp_name}</td>
                        <td>{rr.assign_emp}</td>
                        <td>{rr.created_by==1?'Client':rr.created_by==2?'Sales':''}</td>
                        <td>{rr.created_at}</td>
                        <td>
                          <span className="btn btn-primary btn-sm" onClick={()=>openModalAction(rr.id)} >Action</span>
                        </td>
                      </tr>
                    ))
                    }

                    <tr>
                      <td colspan="11">
                        <Pagination className="custom-pagination">
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={number}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link-style"}
                            breakLinkClassName={"break-link-style"}
                            previousLinkClassName={"prev-link-style"}
                            nextLinkClassName={"next-link-style"}
                          />
                        </Pagination>
                      </td>
                    </tr>
                  </table>
                </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <Dialog open={actionModal} fullWidth PaperProps={{ style: { padding: '20px',maxWidth: '50%' } }}>
        <DialogTitle>Request Details</DialogTitle>
        <DialogContent style={{ height: "500px"}}>
       
            <div className="row">

              <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                <p style={{ margin: "10px" }}>Request Details</p>
                <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
              </div>

              <div className="row">
              {requestDetails && requestDetails.map((rd)=>(
               <>
                  <div className="col-sm-6">
                    Company Name : <b>{rd.business_name}</b><br/>
                    Package Name : <b>{rd.package_name}</b><br/>
                    Package Status : <b>{rd.package_status==0?'Pending':rd.package_status==1?<span style={{color:'green'}}>Active</span>:rd.package_status==2?<span style={{color:'red'}}>Deactive</span>:rd.package_status ==3?'Stop due to Due Payment':rd.package_status==4?'Expire':''}</b><br/>
                    Created By : <b>{rd.created_by==1?'Client':'Sales'}</b><br/>
                    Duration : <b>{rd.package_duration==1?'Lead Based':'Unlimited'}</b><br/>  
                    Package Type : <b>{rd.package_type}</b><br/>  
                  </div>

                  <div className="col-sm-6">
                    Request  : <b>{rd.service_title}</b><br/>
                    Request For : <b>{rd.req_title}</b><br/>
                    Created Date : <b>{rd.created_at}</b><br/>
                    Assign To : <b>{rd.assign_emp}</b><br/>
                    Description : <b>{rd.complaint}</b><br/>
                    Status : <b>{rd.ticket_title}</b><br/>
                  </div>

                  <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                    <p style={{ margin: "10px" }}>Action Details</p>
                    <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  </div>

                  <div className="col-sm-12">
                    {rd.support_category_id==1 &&
                      <>
                        <div className="col-sm-12">
                          <label>Remarks</label>
                          <input type="text" className="form-control" value={packageRemarks} onChange={(e)=>setPackageRemarks(e.target.value)}/>
                          {validationErrors.packageRemarks && (
                            <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.packageRemarks}</div>
                          )}
                        </div>
                        <p>
                          <br />
                        {rd.package_status==1?
                        <p style={{color:'red'}}><b>Are you sure you want to deactivate this package?</b><span className="btn btn-primary btn-sm" onClick={()=>packageActivateDeactvate(rd.client_id,rd.id,rd.package_id,2)}>Yes</span></p>
                        :<p style={{color:'green'}} onClick={()=>packageActivateDeactvate(rd.client_id,rd.id,rd.package_id,1)}>Are you sure you want to activate this package?<span className="btn btn-primary btn-sm">Yes</span></p>}

                        </p>
                        <p style={{color:'green'}}><br/>{packageReturnMessage}</p>
                      </>
                    }
                  {/* {(rd.support_category_id==2 || rd.support_category_id==17) &&
                    <>
                    {rd.support_category_id=2?
                        <p style={{color:'red'}}><b>For Area Update, you will be redirected to a new tab where you can view the client's profile page and update the package area..</b>  <a href={`/mature-client-profile/${rd.client_id}`} target="_blank" className="btn btn-primary btn-sm"  rel="noopener noreferrer">View Profile</a></p>
                        :
                        rd.support_category_id=17?
                        <p style={{color:'red'}}><b>For To Location Area Update, you will be redirected to a new tab where you can view the client's profile page and update the package area..</b>  <a href={`/mature-client-profile/${rd.client_id}`} className="btn btn-primary btn-sm" target="_blank" rel="noopener noreferrer">View Profile</a></p>:''
                      
                    }
                    </>
                  } */}

                  {(rd.support_category_id === 2 || rd.support_category_id === 17) && (
                    <>
                      {rd.support_category_id === 2 ? (
                        <p style={{ color: 'red' }}>
                          <b>For Area Update, you will be redirected to a new tab where you can view the client's profile page and update the package area.</b>
                          <a
                            href={`/mature-client-profile/${rd.client_id}`}
                            target="_blank"
                            className="btn btn-primary btn-sm"
                            rel="noopener noreferrer"
                          >
                            View Profile
                          </a>
                        </p>
                      ) : rd.support_category_id === 17 ? (
                        <p style={{ color: 'red' }}>
                          <b>For To Location Area Update, you will be redirected to a new tab where you can view the client's profile page and update the package area.</b>
                          <a
                            href={`/mature-client-profile/${rd.client_id}`}
                            className="btn btn-primary btn-sm"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Profile
                          </a>
                        </p>
                      ) : (
                        ''
                      )}
                    </>
                  )}

                 

                  {/* <div className="row">
                    <div className="col-sm-3">
                      <label>Select Status</label>
                      <select className="form-control" value={selectedTicketStatus} onChange={(e)=>setSelectedTicketStatus(e.target.value)}>
                        <option value="">Select Status</option>
                        {ticketStatus && ticketStatus.map((ts, index)=>
                          <option value={ts.id}>{ts.title}</option>
                        )}
                      </select>
                      {validationErrors.selectedTicketStatus && (
                            <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedTicketStatus}</div>
                          )}
                    </div>
                    <div className="col-sm-6">
                      <label>Request Remarks</label>
                      <input type="text" className="form-control" value={requestRemarks} onChange={(e)=>setRequestRemarks(e.target.value)}/>
                      {validationErrors.requestRemarks && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.requestRemarks}</div>
                      )}
                    </div>

                    <div className="col-sm-3">
                      <br/>
                      <span className='btn btn-primary btn-sm' onClick={()=>updateRequestStatus(rd.id)}>Update Request Status</span>
                    </div>
                  </div> */}

                <div className="row">
                  <div className="col-sm-3">
                    <label>Select Status</label>
                    <select className="form-control" value={selectedTicketStatus} onChange={(e)=>setSelectedTicketStatus(e.target.value)}>

                      <option value="">Select Status</option>
                      {ticketStatus && ticketStatus.map((ts, index) => (
                        <option key={index} value={ts.id}>{ts.title}</option>
                      ))}
                    </select>
                    {validationErrors.selectedTicketStatus && (
                      <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedTicketStatus}</div>
                    )}
                  </div>

                  <div className="col-sm-6">
                    <label>Request Remarks</label>
                    <input type="text" className="form-control" value={requestRemarks} onChange={(e) => setRequestRemarks(e.target.value)} />
                    {validationErrors.requestRemarks && (
                      <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.requestRemarks}</div>
                    )}
                  </div>

                  <div className="col-sm-3">
                    <br />
                    <span className='btn btn-primary btn-sm' onClick={() => updateRequestStatus(rd.id)}>Update Request Status</span>
                  </div>
                </div>

                </div>
                  
               
                </>
                
              ))}
              </div>
              </div>
        
        </DialogContent>

        <DialogActions>
          <Link className="btn btn-primary btn-sm" onClick={closeRequestModal}>Close</Link>
        </DialogActions>
      </Dialog>
    

    </DashboardLayout>
  );
}
export default Request_details;
