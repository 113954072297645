import React from 'react';
import { Link, useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
function ReportsDetailsHeader() 
{
  const dept_id = secureLocalStorage.getItem('department_id');
  let location = useLocation();
  let currentPage = location.pathname;
  return (
    <div className='d-flex justify-content-between mb-3' style={{ overflowY: 'hidden' }}>

    {(dept_id == 9 || dept_id == 4) && 
    <div className='px-1'>
      <Link style={currentPage === "/cs-reports" ? { backgroundColor: '#17A2B8',borderColor:'#17A2B8'  } : { backgroundColor: "" }} className="btn btn-primary mb-2 " to="/cs-reports">
        Executive Reports
      </Link>
    </div>
    }

    {dept_id == 9 &&
    <div className='px-1'>
      <Link style={currentPage === "/all-clients-reports" ? { backgroundColor: '#17A2B8',borderColor:'#17A2B8'  } : { backgroundColor: "" }} className="btn btn-primary mb-2 " to="/all-clients-reports">
        Client Reports
      </Link>
    </div>
    }

    {(dept_id == 9 || dept_id == 10 || dept_id == 4) &&
    <div className='px-1'>
      <Link style={currentPage === "/enquiry-report" ? { backgroundColor: '#17A2B8',borderColor:'#17A2B8'  } : { backgroundColor: "" }} className="btn btn-primary mb-2 " to="/enquiry-report">
        Enquiry Reports
      </Link>
    </div>
    }

    {dept_id == 9 &&
    <div className='px-1'>
      <Link style={currentPage === "/hourly-enquiry-report" ? { backgroundColor: '#17A2B8',borderColor:'#17A2B8'  } : { backgroundColor: "" }} className="btn btn-primary mb-2 " to="/hourly-enquiry-report">
        Hourly Enquiry Reports
      </Link>
    </div>
    }

    {(dept_id == 9 || dept_id == 10 || dept_id == 4) &&
      <div className='px-1'>
        <Link style={currentPage === "/active-package" ? { backgroundColor: '#17A2B8',borderColor:'#17A2B8'  } : { backgroundColor: "" }} className="btn btn-primary mb-2 " to="/active-package">
          Active Package
        </Link>
      </div>
    }

    {dept_id == 9 &&
      <div className='px-1'>
        <Link style={currentPage === "/download-report" ? { backgroundColor: '#17A2B8',borderColor:'#17A2B8'  } : { backgroundColor: "" }} className="btn btn-primary mb-2 " to="/download-report">
          Download Reports
        </Link>
      </div>
    }




  </div>
  );
}
export default ReportsDetailsHeader;
