import { useState, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import Pusher from "pusher-js";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";

function TicketEvent() {
  const emp_id = secureLocalStorage.getItem('emp_id');
  const navigate = useNavigate();

  const handleViewClick = async (data, ticket_id) => {
    try {
      const response = await dashboardApi.post('ticket-notification-seen-status', {
        emp_id: emp_id,
        type: data,
      });

      if (response.data.status === 200) {
        switch (data) {
          case 'ticket_raise':
            navigate('/ticket-receive');
            break;
          case 'ticket_assign':
            navigate('/ticket-assign');
            break;
          case 'ticket_status':
            navigate(`/view-ticket-status/${ticket_id}`);
            break;
          case 'ticket_time_change':
            navigate('/new-time-request-approval');
            break;
          case 'ticket_time_approval':
            navigate(`/view-ticket-status/${ticket_id}`);
            break;
          case 'action_time_check':
            navigate('/org-ticket-list');
            break;
          default:
            console.error('Unknown ticket type:', data);
        }
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const pusher = new Pusher("95196b7509f2295a4958", {
      cluster: "ap2",
      encrypted: true,
    });

    const channel = pusher.subscribe(`ticket-channel-${emp_id}`);
    channel.bind("ticket-event", (data) => {
      const audio = new Audio('https://test.biz2bizmart.com/profile_picture/mixkit-bell-notification-933.wav');
      audio.play();

      toast.success(
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>{data.message}</span>
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          style: { background: 'green', color: 'white', width: '700px' },
          closeButton: false,
          onClick: () => handleViewClick(data.type, data.ticket_id),
        }
      );
    });

    return () => {
      pusher.unsubscribe(`ticket-channel-${emp_id}`);
      pusher.disconnect();
    };
  }, [emp_id]);

  return (
    <>
      <ToastContainer closeButton={false} />
    </>
  );
}

export default TicketEvent;
