import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import MDBox from 'components/MDBox';
import DashboardLayout from 'theme_layout/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'theme_layout/Navbars/DashboardNavbar';
import Footer from 'theme_layout/Footer';
import Card from "@mui/material/Card";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dashboardApi from 'layouts/common_components/apibase_url';
import secureLocalStorage from 'react-secure-storage';
import CsDetailsHeader from 'layouts/common_components/cs_details_header';

const No_of_attempts = () => {
  const navigate = useNavigate();
  const { type } = useParams();
  const token = secureLocalStorage.getItem('token');
  const [productlist, setProductlist] = useState([]);
  const [product, setProduct] = useState('');
  const [servicelist, setServicelist] = useState([]);
  const [service, setService] = useState('');
  const [start_date, setStartDate] = useState('');
  const [end_date, setEndDate] = useState('');
  const [grouplist, setGroupList] = useState([]);
  const [group, setGroup] = useState('');
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const emp_id =  secureLocalStorage.getItem('emp_id');
  const postsPerPage = 10;
  const [category_list,set_category_list] = useState([]);
  const [category,selectedcategory] = useState('');
  const[enq_status,set_enq_status] = useState([]);
  const [selectedstatus,setselected_status] = useState('');

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(() => {
    groupDetails();
    productDetails();
    get_enq_status();
  }, []);

  useEffect(() => {
    getData(currentPage);
  }, [currentPage, product, service, start_date, end_date, group,category,type,selectedstatus]);

  const groupDetails = async () => {
    try {
      const response = await dashboardApi.get('group-details');
      if (response.data.status === 200) {
        setGroupList(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const get_enq_status = async () => {
    try {
      const response = await dashboardApi.get('show-enq-status');
      if (response.data.status === 200) {
        set_enq_status(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const productDetails = async () => {
    try {
      const response = await dashboardApi.get('get-product-list');
      if (response.data.status === 200) {
        setProductlist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (product) {
      const fetchData = async () => {
        try {
          const response = await dashboardApi.get(`get-service-list-by-product-id/${product}`);
          if (response.data.status === 200) {
            setServicelist(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [product]);


   useEffect(() => {
    if (service) {
      const fetchData = async () => {
        try {
          const endpoint = `get-category-based-on-service-id/${service}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            set_category_list(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [service]);

  const getData = async (page) => {
    setLoading(true);
    try {
      const response = await dashboardApi.get('no-of-attempts-on-enquiry', {
        params: {
          per_page: postsPerPage,
          page: page + 1,
          product: product,
          service: service,
          start_date: start_date,
          end_date: end_date,
          group: group,
          category:category,
          emp:emp_id,
          attempt_type:type,
          enq_status:selectedstatus,
        }
      });
    

      if (response.data.status === 200) {
        const enqData = response.data.data.data || response.data.data;
        setList(enqData);
        setTotalPages(response.data.last_page);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setCurrentPage(selectedPage);
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card>
          <div>
           <div class="col-sm-11">
                          <CsDetailsHeader></CsDetailsHeader>
                      </div>
            <div className="d-flex flex-wrap">
              <div className="col-sm-3 col-6 p-1">
                <select
                  className="form-control form-select"
                  value={group}
                  onChange={(e) => setGroup(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option value="">Select Group</option>
                  {grouplist.map((row) => (
                    <option key={row.group_id} value={row.group_id}>
                      {row.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-3 col-6 p-1">
                <select
                  className="form-control form-select"
                  value={product}
                  onChange={(e) => setProduct(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option value="">Select Product</option>
                  {productlist.map((row) => (
                    <option key={row.id} value={row.id}>
                      {row.product_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-3 col-6 p-1">
                <select
                  className="form-control form-select"
                  value={service}
                  onChange={(e) => setService(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option value="">Select Service</option>
                  {servicelist.map((ser) => (
                    <option key={ser.id} value={ser.id}>
                      {ser.service_name}
                    </option>
                  ))}
                </select>
              </div>

               <div className="col-sm-3 col-6 p-1">
              <select
                className="form-control form-select"
                value={category}
                onChange={(e) => selectedcategory(e.target.value)}
                aria-label="Default select example"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              >
                <option value="">Select Category</option>
                {category_list.map((row) => (
                  <option key={row.id} value={row.id}>
                    {row.category_name}
                  </option>
                ))}
              </select>
            </div>

              <div className="col-sm-3 col-6 p-1">
                <input
                  type="date"
                  value={start_date}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="form-control"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                />
              </div>
              <div className="col-sm-3 col-6 p-1">
                <input
                  type="date"
                  value={end_date}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="form-control"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                />
              </div>
            </div>
          </div>


          <div className="col-sm-3 col-6 p-1">
                <select
                  className="form-control form-select"
                  value={selectedstatus}
                  onChange={(e) => setselected_status(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option value="">Select Enq Status</option>
                  {enq_status.map((row) => (
                    <option key={row.id} value={row.id}>
                      {row.name}
                    </option>
                  ))}
                </select>
              </div>

          <div className="card-body">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div className="table-responsive">
                <table className="table table-bordered table-hovered">
                  <thead>
                    <tr>
                      <td style={{ fontSize: '12px' }}>Enquiry_id</td>
                       <td style={{ fontSize: '12px' }}>Customer Name</td>
                      <td style={{ fontSize: '12px' }}>Group</td>
                      <td style={{ fontSize: '12px' }}>Product</td>
                      <td style={{ fontSize: '12px' }}>Category</td>
                      <td style={{ fontSize: '12px' }}>Service</td>
                      <td style={{ fontSize: '12px' }}>No Of Attempts</td>
                      <td style={{ fontSize: '12px' }}>Created Date</td>
                      <td style={{ fontSize: '12px' }}>Attempts By</td>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((row) => (
                      <tr key={row.id}>
                        <td style={{ fontSize: '12px' }}>{row.enquiry_id}</td>
                        <td style={{ fontSize: '12px' }}>{row.customer_name}</td>
                         <td style={{ fontSize: '12px' }}>{row.group_name}</td>
                        <td style={{ fontSize: '12px' }}>{row.product_name}</td>
                        <td style={{ fontSize: '12px' }}>{row.category_name}</td>
                        <td style={{ fontSize: '12px' }}>{row.service_name}</td>
                        <td style={{ fontSize: '12px' }}>{row.no_of_attempt}</td>
                        <td style={{ fontSize: '12px' }}>{row.created_date}</td>
                        <td style={{ fontSize: '12px' }}>{row.attempts}</td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          {totalPages > 0 && (
            <Pagination className="custom-pagination">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                pageLinkClassName={"page-link-style"}
                breakLinkClassName={"break-link-style"}
                previousLinkClassName={"prev-link-style"}
                nextLinkClassName={"next-link-style"}
              />
            </Pagination>
          )}
        </Card>
      </MDBox>
      <MDBox mt={4.5} />
      <Footer />
      <ToastContainer />
    </DashboardLayout>
  );
}

export default No_of_attempts;
