import React, { useState,useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import dashboardApi from "layouts/common_components/apibase_url";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import  secureLocalStorage  from  "react-secure-storage";
import LoadingModal from 'layouts/loaderModal/LoadingModal';

function MatureClientProfileHeader()
{
  const empId = secureLocalStorage.getItem('emp_id');
  const navigate = useNavigate();
  const { clientId } = useParams();
  
  const [validationErrors, setValidationErrors] = useState({});
  const [clientDetails,setClientDetails] = useState({});
  const [packageCount,setPackageCount] = useState({});
  const [walletHistory,setWalletDetails] = useState({});
  const [paymentModal,setPaymentModal] = useState(false);
  const [newAmount,setNewAmount] = useState('');
  const [paymentType,setPaymentType] = useState('');
  const [bankName,setBankName] = useState('');
  const [cashType,setCashType] = useState('');
  const [utrNo,setUtrNo] = useState('');
  const [transactionId,setTransactionId] = useState('');
  const [paymentDate,setPaymentDate] = useState('');
  const [remarks,setRemarks] = useState('');
  const [wallet_id,setWalletId] = useState('');
  const [packageloading, setPackageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [compDetails, setCompDetails] = useState([]);
  const [selectedComp, setSelectedComp] = useState();
  //const [wallet_amount,setWalletAmount] = useState();

  const [bankDetails,setBankDetails]=useState([]);
  let clientType = 1;
 
  const addWalletAmount = async (e) => {
    e.preventDefault();
    const errors = {};
    
    // Validation checks
    if (!selectedComp) {
      errors.selectedComp = 'Company is required';
    }
    if (!newAmount) {
      errors.newAmount = 'Amount is required';
    } else if (!/^\d+$/.test(newAmount)) {
      errors.newAmount = 'Amount must be an integer';
    } else if (newAmount < 1) {
      errors.newAmount = 'Minimum amount must be greater than 1';
    }
  
    if (!paymentType) {
      errors.paymentType = 'Payment Type is required';
    }
  
    if (!bankName && (cashType === 'Bank' || paymentType !== 'Cash')) {
      errors.bankName = 'Bank is required';
    }
  
    if (!utrNo) {
      errors.utrNo = 'UTR number is required';
    }
  
    if (!transactionId) {
      errors.transactionId = 'Transaction ID is required';
    }

    // Check for duplicates
    const isDuplicateUTR = await checkDuplicateUTR(utrNo);
    const isDuplicateTransaction = await checkDuplicateTransaction(transactionId);

    if(isDuplicateUTR)
    {
      errors.utrNo = "UTR number is already used";
    }

    if(isDuplicateTransaction)
    {
      errors.transactionId = "Transaction ID is already used";
    }

    setValidationErrors(errors);
    if(Object.keys(errors).length > 0)
    {
      return; // Stop execution if there are validation errors
    }
  
    const formSubmit = new FormData();
    formSubmit.append('amount', newAmount);
    formSubmit.append('payment_type', paymentType);
    formSubmit.append('bank_name', bankName);
    formSubmit.append('cash_type', cashType);
    formSubmit.append('utr_no', utrNo.toUpperCase());
    formSubmit.append('transaction_id', transactionId.toUpperCase());
    formSubmit.append('payment_date', paymentDate);
    formSubmit.append('remarks', remarks);
    formSubmit.append('created_by', empId);
    formSubmit.append('client_id', clientId);
    formSubmit.append('comp_id', selectedComp);
    formSubmit.append('wallet_id', wallet_id);
    setIsLoading(true);
    try {
      const response = await dashboardApi.post('add-new-wallet-amount', formSubmit);
      if (response.data.status === 200) {
        setIsLoading(false);
        resetFormFields(); // Reset fields on successful submission
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        setPaymentModal(false); // Close modal after successful submission
      } else {
        setIsLoading(false);
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while processing the request',
      });
    }
  };

  const fetchCompanyDetails = async () => {
    try {
      const response = await dashboardApi.get(`get-client-company-details/${clientId}`);
      if (response.data.status === 200) {
        setCompDetails(response.data.data);
      } else {
        console.error('Error fetching countries:', response.data.message);
      }
   
    } catch (error) {
      console.error('Error fetching countries:', error);
   
    }
  }

  // Function to check for duplicate UTR numbers
  const checkDuplicateUTR = async (utrNo) => {
    try {
      const response = await dashboardApi.get(`/check-duplicate-utr/${utrNo}`);
      return response.data.isDuplicate;
    } catch (error) {
      console.error("Error checking duplicate UTR:", error);
      return false; // Handle error and assume not duplicate to allow submission
    }
  };

  // Function to check for duplicate Transaction IDs
  const checkDuplicateTransaction = async (transactionId) => {
    try {
      const response = await dashboardApi.get(`/check-duplicate-transaction/${transactionId}`);
      return response.data.isDuplicate;
    } catch (error) {
      console.error("Error checking duplicate Transaction ID:", error);
      return false; // Handle error and assume not duplicate to allow submission
    }
  };

  const paymentOpenControl=()=>{
    setPaymentModal(true);
  }
  
  const closePaymentControl = ()=>
  {
    setPaymentModal(false);
    resetFormFields();
  }
  
  useEffect(()=>{
    checkClientDetails();
    getClientPackage();
    getWalletDetails();
    fetchCompanyDetails();
    getBankDetails();
  },[])
  
    const checkClientDetails = async () =>
    {
      try {
          const response = await dashboardApi.get(`mature-client-details/${clientId}`);
          if (response.data.status === 200)
          {
            setClientDetails(response.data.data);
          } 
        }
        catch(error)
        {
          console.error('Error fetching countries:', error);
        }
    }
    const getClientPackage = async () =>
    {
      try {
          const response = await dashboardApi.get(`get-package-count/${clientId}`);
          if(response.data.status === 200)
          {
            setPackageCount(response.data.data);
          } 
        }
        catch(error)
        {
          console.error('Error fetching countries:', error);
        }
    }

    const getWalletDetails = async () =>
    {
      try {
          const response = await dashboardApi.get(`get-wallet-details/${clientId}/${clientType}`);
          if(response.data.status === 200)
          {
            setWalletDetails(response.data.data);
            setWalletId(response.data.data.wallet_id);
          } 
        }
        catch(error)
        {
          console.error('Error fetching countries:', error);
        }
    }

    const getBankDetails = async () =>
    {
      try {
          const response = await dashboardApi.get(`get-bank-details-listing`);
          if(response.data.status === 200)
          {
            setBankDetails(response.data.data);
           
          } 
        }
        catch(error)
        {
          console.error('Error fetching countries:', error);
        }
    }

    const handleAmountChange = (e) => {
      // Replace any non-digit characters with an empty string
      const value = e.target.value.replace(/\D/g, '');
      setNewAmount(value);
    };

    const resetFormFields = () => {
      setNewAmount('');
      setPaymentType('');
      setBankName('');
      setCashType('');
      setUtrNo('');
      setTransactionId('');
      setPaymentDate('');
      setRemarks('');
    };

    return(
      <>
         <div className="d-flex flex-wrap" style={{width:'100%',overflowY: "hidden"}}>
            <LoadingModal isLoading={isLoading} />
            <div className="col-sm-4 col-12 p-2">
              <div className="p-2 h-100 border rounded"  style={{backgroundColor: "#f0f2f5"}}>
                
                <span style={{display:'flex'}}><h5>Client Information</h5><input type="radio" color="success"  name="" value="" style={{backgroundColor:'green',border:'green',marginLeft: '50%',color:'green'}} checked/> 
                </span>
                <h6><b>Client Type :</b>{clientDetails.status_name}</h6>
                <h6><b>Name : </b> {clientDetails.client_name}</h6> 
                <h6><b>Pan Card No : </b>{clientDetails.pan_card}</h6>
                <h6><b>Email Id: </b>{clientDetails.email}</h6>
                <h6><b>Mobile No : </b>{clientDetails.mobile_no}</h6>
                {/* <h6><b>City : </b>{clientDetails.city} ({clientDetails.state})</h6> */}
                <h6><b>Address : </b>{clientDetails.address} , {clientDetails.zipcode}</h6>
              </div>
            </div>
            <div className="col-sm-4 col-12 p-2">
              <div className="p-2 h-100 border rounded"  style={{backgroundColor: "#f0f2f5"}}>
                <span style={{display:'flex'}}><h5>Package Information</h5><input type="radio" color="success"  name="" value="" style={{backgroundColor:'green',border:'green',marginLeft: '45%',color:'green'}} checked/> </span>
                <h6><b>Total Packages :  </b> {packageCount.total_package}  </h6>
                <h6><b>Active Package :  </b> {packageCount.active_package}</h6>
                <h6><b>Deactive Packages : </b> {packageCount.deactive_package}</h6> 
              </div>
            </div>
            <div className="col-sm-4 col-12 p-2" >
              <div className="p-2 h-100 border rounded"  style={{backgroundColor: "#f0f2f5"}}>
                <h6><b>Account & Wallet Information</b>
                <Link className="btn btn-success btn-primary btn-sm" style={{float:'right'}} onClick={paymentOpenControl}>💷</Link></h6>
                
                <h6><b>IFSC Code :</b> ICIC0000104</h6>
                <h6><b>Branch Name :</b> Mumbai</h6>
                <h6><b>Wallet Id :</b> RIMS-0{walletHistory && walletHistory.client_id}-0{walletHistory && walletHistory.wallet_id}</h6>
                <h6><b>Credit Amount : </b> {walletHistory && walletHistory.credit_amount} </h6>
                <h6><b>Debit Amount : </b> {walletHistory && walletHistory.debit_amount} </h6>
                <h6><b>Balance Amount : </b> {walletHistory && walletHistory.balance_amount} </h6>
              </div>
            </div>
          </div>
       


      <Dialog open={paymentModal} fullWidth PaperProps={{ style: { maxWidth: '50%', padding: '20px' } }}>
        <DialogTitle>Add Amount</DialogTitle>
        <DialogContent style={{ height: "350px" }}>
          <form id="walletForm" onSubmit={addWalletAmount}>
            <div className="row">
              <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                <p style={{ margin: "10px" }}>Add Wallet Amount</p>
                <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
              </div>

              <div className="col-sm-6">
                <label>Select Company</label>
                <select
                  className="form-control"
                  value={selectedComp}
                  onChange={(e) => setSelectedComp(e.target.value)}
                >
                  <option value="">Select Company</option>
                  {compDetails &&
                    compDetails.map((comp) => (
                      <option key={comp.comp_id} value={comp.comp_id}>
                        {comp.business_name} ({comp.mobile_no})
                      </option>
                    ))}
                </select>
                {validationErrors.compDetails && (
                  <div className="text-danger" style={{ fontSize: '15px' }}>
                    {validationErrors.compDetails}
                  </div>
                )}
              </div>

              <div className="col-sm-6">
                <label>Enter Amount</label>
                <input
                  type="text"
                  name="enter_amount"
                  className="form-control"
                  value={newAmount}
                  onChange={(e) => handleAmountChange(e)}
                  pattern="\d*"
                  inputMode="numeric"
                  required
                />
                {validationErrors.newAmount && (
                  <div className="text-danger" style={{ fontSize: '15px' }}>
                    {validationErrors.newAmount}
                  </div>
                )}
              </div>

              <div className="col-sm-6">
                <label>Payment Type</label>
                <select
                  className="form-control"
                  value={paymentType}
                  onChange={(e) => setPaymentType(e.target.value)}
                  required
                >
                  <option value="">Select Payment Type</option>
                  <option value="Cash">Cash</option>
                  <option value="Cheque">Cheque</option>
                  <option value="DD">DD</option>
                  <option value="Online">Online</option>
                  <option value="NEFT">NEFT</option>
                  <option value="IMPS">IMPS</option>
                
                </select>
              </div>

              {paymentType === "Cash" && (
                <div className="col-sm-6">
                  <label>Select Cash Type</label>
                  <select
                    className="form-control"
                    value={cashType}
                    onChange={(e) => setCashType(e.target.value)}
                  >
                    <option value="">Select Cash Type</option>
                    <option value="cash_in_hand">Cash In Hand</option>
                    <option value="Bank">Bank Deposit</option>
                  </select>
                </div>
              )}

              {(cashType === "Bank" || paymentType !== "Cash") && (
                <div className="col-sm-6">
                  <label>Select Bank Name</label>
                  <select
                    className="form-control"
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                  >
                    <option value="">Select Bank Name</option>
                    {bankDetails && bankDetails.map((bd)=>(
                       <option value={bd.id}>{bd.bank_name}</option>
                    ))}
                  </select>
                </div>
              )}

              <div className="col-sm-6">
                <label>Enter UTR No</label>
                <input
                  type="text"
                  name="utr_no"
                  className="form-control"
                  value={utrNo.toUpperCase()} // Capitalize input
                  onChange={(e) => setUtrNo(e.target.value.toUpperCase())} // Force uppercase input
                />
                 {validationErrors.utrNo && (
                  <div className="text-danger" style={{ fontSize: '15px' }}>
                    {validationErrors.utrNo}
                  </div>
                )}
              </div>

              <div className="col-sm-6">
                <label>Enter Transaction Id</label>
                <input
                  type="text"
                  name="txn_no"
                  className="form-control"
                  value={transactionId.toUpperCase()} // Capitalize input
                  onChange={(e) => setTransactionId(e.target.value.toUpperCase())} // Force uppercase input
                />
                {validationErrors.transactionId && (
                  <div className="text-danger" style={{ fontSize: '15px' }}>
                    {validationErrors.transactionId}
                  </div>
                )}
              </div>

              {/* <div className="col-sm-6">
                <label>Payment Date</label>
                <input
                  type="date"
                  name="txn_date"
                  className="form-control"
                  value={paymentDate}
                  onChange={(e) => setPaymentDate(e.target.value)}
                  required
                />
                {validationErrors.paymentDate && (
                  <div className="text-danger" style={{ fontSize: '15px' }}>
                    {validationErrors.paymentDate}
                  </div>
                )}
              </div> */}

              <div className="col-sm-6">
                <label>Payment Date</label>
                <input
                  type="date"
                  name="txn_date"
                  className="form-control"
                  value={paymentDate}
                  onChange={(e) => setPaymentDate(e.target.value)}
                  max={new Date().toISOString().split('T')[0]} // Restrict to today or earlier
                  required
                />
                {validationErrors.paymentDate && (
                  <div className="text-danger" style={{ fontSize: '15px' }}>
                    {validationErrors.paymentDate}
                  </div>
                )}
              </div>

              <div className="col-sm-6">
                <label>Remarks</label>
                <input
                  type="text"
                  name="remarks"
                  className="form-control"
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                  required
                />
                {validationErrors.remarks && (
                  <div className="text-danger" style={{ fontSize: '15px' }}>
                    {validationErrors.remarks}
                  </div>
                )}
              </div>
            </div>
          </form>
        </DialogContent>

        <DialogActions>
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={closePaymentControl}
          >
            Close
          </button>

          <button
            type="submit"
            form="walletForm"
            className="btn btn-success btn-sm"
          >
            Add Payment
          </button>
        </DialogActions>
      </Dialog>

      </>
    );
}
export default MatureClientProfileHeader;
