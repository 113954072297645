
import React from 'react';
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";  

const LoadingModal = ({ isLoading }) => {
    if (!isLoading) return null;
  
    return (
    <DashboardLayout>
      <Dialog
      open={isLoading}
      fullWidth
      PaperProps={{
        style: {
          padding: '20px',
          backgroundColor: 'rgba(255, 255, 255, 0.8)', // Transparent white
          boxShadow: 'none', // Remove any box-shadow for a cleaner look
          backdropFilter: 'blur(5px)', // Adds a slight blur to the background
        },
      }}
    >
    <DialogTitle
      style={{
        fontSize: '1.5rem',
        fontWeight: 'bold',
        textAlign: 'center',
        color: 'rgb(183 33 33)', // Dark color for a professional look
        borderBottom: '1px solid #ccc', // Optional: Adds a subtle underline
        marginBottom: '10px',
      }}
    >
      This will only take a few seconds...
    </DialogTitle>
    
    <DialogContent style={{ height: 'auto', display: 'flex', justifyContent: 'center' }}>
      <CircularProgress />
    </DialogContent>
  </Dialog>
  </DashboardLayout>
    );
  };
  export default LoadingModal;