import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import ReportsDetailsHeader from 'layouts/common_components/reports_details_header';

import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import secureLocalStorage from "react-secure-storage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import "layouts/css/global.css";
import { parseISO, isBefore, format } from 'date-fns';


function All_client_reports()
{
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clientReports, setClientReports] = useState([]);
  const [exeDetails, setExeDetails] = useState([]);
  const [selectedEmp, setSelectedEmp] = useState('');
  const [currentPage, setCurrentPage] = useState(0); 
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(10);
  const [selectFromDate,setFilterDateFrom] = useState('');
  const [selectToDate,setFilterDateTo] = useState('');
  const [dateError, setError] = useState('');

  const [productList, setProductList] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [groupList, setGroupList] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState();
  const [clientStatus, setClientStatus] = useState();

  useEffect(()=>{
    getExeDetails();
    getGroupList();
    getProductList();
  },[]);

  useEffect(()=>{
    getAllClientReports();
  },[currentPage])

  const handleFromDateChange = (e) => {
    setFilterDateFrom(e.target.value);
    validateDates(e.target.value, selectToDate);
  };

  const handleToDateChange = (e) => {
    setFilterDateTo(e.target.value);
    validateDates(selectFromDate, e.target.value);
  };

  const validateDates = (from, to) => {
    if (from && to) {
      const fromDateObj = parseISO(from);
      const toDateObj = parseISO(to);

      if (isBefore(toDateObj, fromDateObj)) {
        setError('To date cannot be before from date.');
        setFilterDateTo('');
      } else {
        setError('');
      }
    }
  };

  const getGroupList = async () => {
    try {
      const response = await dashboardApi.get(`get-group-list`);
      if (response.data.status === 200) {
        setGroupList(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching group list:', error);
    }
  }

  const getProductList = async () => {
    try {
      const type = "active";
      const response = await dashboardApi.get(`sales-product-list/${type}`);
      if (response.data.status === 200) {
        setProductList(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching product list:', error);
    }
  }


 const getAllClientReports = async () =>
    {
      try
      {
        
        const response = await dashboardApi.get(`get-all-client-reports`,{
          params:{
            emp_id:emp_id,
            from_date:selectFromDate,
            to_date:selectToDate,
            per_page: postPerPage,
            page: currentPage + 1,
          }
        });

        if(response.data.status === 200)
        {
          const responseData = response.data.data;
          console.log(responseData);
          const exeData = responseData.data || responseData;
          setClientReports(exeData);
          setNumber(responseData.last_page);
          setFilterDateFrom(responseData.from_date);
          setFilterDateTo(responseData.to_date);
        } else {
          console.error('Error fetching countries:', response.data.message);
        }
        setLoading(false);
      }
      catch (error)
      {
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
    }

   

    const getExeDetails = async () => 
    {
      try{
          const response = await dashboardApi.get(`get-executive-details/${emp_id}`);
          if (response.data.status === 200) {
            setExeDetails(response.data.data);
          } else {
            console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
      }catch (error){
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
    }

    const handlePageClick = (selected) => {
      setCurrentPage(selected.selected);
    };

  return(
    <DashboardLayout>
    <DashboardNavbar />
    <MDBox pt={3} pb={3}>
      <Card>
        <div className="card-body p-3">
          <ReportsDetailsHeader></ReportsDetailsHeader>
          <div className='d-flex flex-wrap align-items-end'>
            <div className='col-sm-3 col-12'>
              <small>Select Eexecutive</small>
              <select className="form-control"
                value={selectedEmp}
                onChange={(e) => setSelectedEmp(e.target.value)}
              >
                <option value=''>Select Eexecutive</option>
                {exeDetails.map((ed) => (
                  <option key={ed.emp_id} value={ed.emp_id}>
                    {ed.emp_fname} {ed.emp_lname}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-sm-3 col-12">
              <small>Product</small>
              <select className="form-control" value={selectedProduct} onChange={(e) => { setSelectedProduct(e.target.value) }}>
                <option value="">Select Product</option>
                {productList && productList.map((pro) => (
                  <option key={pro.id} value={pro.id}>{pro.product_name}</option>
                ))}
              </select>
            </div>

            <div className="col-sm-3 col-12">
              <small>Group</small>
              <select className="form-control" value={selectedGroup} onChange={(e) => { setSelectedGroup(e.target.value) }}>
                <option value="">Select Group</option>
                {groupList && groupList.map((group) => (
                  <option key={group.group_id} value={group.group_id}>{group.name}</option>
                ))}
              </select>
            </div>

            <div className="col-sm-3 col-12">
              <small>Status</small>
              <select className="form-control" value={clientStatus} onChange={(e) => { setClientStatus(e.target.value) }}>
                <option value="">Select Status</option>
                <option value="2">Active</option>
                <option value="3">Inactive</option>
                <option value="4">Expired</option>
                <option value="1">Verified</option>
              </select>
            </div>

            <div className="col-sm-3 col-12">
              <small htmlFor="fromDate">From Date:</small>
              <input
                type="date"
                id="fromDate"
                className="form-control"
                onChange={handleFromDateChange}
                value={selectFromDate}
              />
            </div>

            <div className="col-sm-3 col-12">
              <small htmlFor="toDate">To Date:</small>
              <input
                type="date"
                className="form-control"
                value={selectToDate}
                onChange={handleToDateChange}
              />
              {dateError && <small className='text-danger d-block'>{dateError}</small>}
            </div>
            <div className="col-sm-3 col-12">
              <br />
              <button className="btn btn-primary">Search</button>
            </div>

          </div>
          <div className='d-flex justify-content-between align-items-center px-2 mt-3'>
            <h5 className='m-0'>Client Reports</h5>
            <button className="btn btn-primary">
              <span class="material-icons-outlined">
                file_download
              </span>
            </button>
          </div>




          {loading ? (
            <p style={{ align: 'center' }}>
              <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{ height: '80px', width: 'auto' }}></img>
            </p>
          ) : (
            <div className='p-2' style={{ overflowY: "hidden" }}>
              <table className="table table-bordered table-hovered" style={{ marginTop: '20px' }}>

                <tr>
                  <td>S.No</td>

                  <td>Client Id</td>
                  <td>Company Id</td>
                  <td>Company Name</td>
                  <td>Business Name</td>
                  <td>Total Amount</td>
                  <td>Total Paid Amount</td>
                  <td>Total Lead</td>
                  <td>Total Sent Lead</td>
                  <td>Status</td>
                </tr>

                {clientReports && clientReports.map((cr, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{cr.client_id}</td>

                    <td>{cr.comp_id}</td>
                    <td>{cr.comp_name}</td>
                    <td>{cr.comp_business_name}</td>
                    <td>{cr.package_amount}</td>
                    <td>{cr.paid_amount}</td>
                    <td>{cr.total_lead}</td>
                    <td>{cr.sent_lead}</td>
                    <td>{cr.comp_status == 1 ? 'Verified' : cr.sent_enquiry == 2 ? 'Active' : cr.sent_enquiry == 3 ? 'Inactive' : cr.sent_enquiry == 4 ? 'Expire' : 'Guest'}</td>
                  </tr>
                ))
                }

              </table>
              <Pagination className="custom-pagination">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={number}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  pageLinkClassName={"page-link-style"}
                  breakLinkClassName={"break-link-style"}
                  previousLinkClassName={"prev-link-style"}
                  nextLinkClassName={"next-link-style"}
                />
              </Pagination>
            </div>
          )}

        </div>
      </Card>
    </MDBox >
    <Footer />
  </DashboardLayout >
  );
}
export default All_client_reports;