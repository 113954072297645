import Grid from '@mui/material/Grid';
import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 React example components
import DashboardLayout from 'theme_layout/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'theme_layout/Navbars/DashboardNavbar';
import Footer from 'theme_layout/Footer';
import ReportsBarChart from 'theme_layout/Charts/BarCharts/ReportsBarChart';
import ReportsLineChart from 'theme_layout/Charts/LineCharts/ReportsLineChart';
import ComplexStatisticsCard from 'theme_layout/Cards/StatisticsCards/ComplexStatisticsCard';

// Data
import reportsBarChartData from 'layouts/dashboard/data/reportsBarChartData';
import reportsLineChartData from 'layouts/dashboard/data/reportsLineChartData';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './sales_funnel_details.css';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Swal from 'sweetalert2';

// Icons
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import PaymentIcon from '@mui/icons-material/Payment';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PhoneIcon from '@mui/icons-material/Phone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Typography from '@mui/material/Typography';
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import CircularProgress from '@mui/material/CircularProgress';

import dashboardApi from "layouts/common_components/apibase_url";
import secureLocalStorage from "react-secure-storage";
const empId = secureLocalStorage.getItem('emp_id');
const dept_id = secureLocalStorage.getItem('department_id');

function Missingkrakpi() {
  const { sales, tasks } = reportsLineChartData;
  const [product, setproduct] = useState('');
  const [productlist, setproductlist] = useState([]);
  const [servicelist, setservicelist] = useState([]);
  const [service, setservice] = useState('');
  const [emp_list, getemp_list] = useState([]);
  const [selectedmanagers, setselectedmanagers] = useState();
  const [sales_data, getsales_data] = useState({});
  const [managersteam, getmanagersteam] = useState([]);
  const [selectedemp, setselectedemp] = useState('');
  const [showAttributeDropdown, setShowAttributeDropdown] = useState(false);
  const [followup_status, get_followup_details] = useState([]);
  const { details } = useParams();
  const [inner_page_details, get_inner_page_details] = useState([]);
  const [sum, get_sum] = useState();
  const [new_amount, get_newamount] = useState('');
  const [manager_team, get_manager_team] = useState([]);
  const [checkmanager, setcheckmanager] = useState('');
  const [remark, setremark] = useState('');
  const [id, setid] = useState('');
  const [openModal, setopenModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [start_date, setstart_date] = useState();
  const [end_date, setend_date] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1);
  const [postPerPage] = useState(5);
  const [loading, setLoading] = useState(false); // Loading state

  useEffect(() => {
    productdetails();
    get_emp_list();
    get_sales_manager_team();
  }, []);

  useEffect(() => {
    sales_inner_page_details_filter();
  }, [start_date, end_date, currentPage]);

  useEffect(() => {
    sales_inner_page_details();
  }, [currentPage]);

  const handleDownload = async () => {
    try {
      const response = await dashboardApi.get('sales-upcoming-renewal-details', {
        params: {
          download: 'csv',
          emp_id: empId,
          start_date: start_date,
          end_date: end_date,
          product: product,
          category: service,
          manager: selectedmanagers,
          employee: selectedemp,
          type: details
        },
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: 'text/csv' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `sales_data_${new Date().toISOString()}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading the CSV file', error);
    }
  };

  const get_sales_manager_team = async () => {
    try {
      const endpoint = `sales-dashboard-show-manager-team/${empId}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        get_manager_team(response.data.data);
        setcheckmanager(response.data.reporting_manager);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const add_remark = (id) => {
    setid(id);
    setopenModal(true);
  }

  const handle_close = () => {
    setopenModal(false);
    setid('');
    setremark('');
  }

  const handle_save = async () => {
    const errors = {};
    if (!remark) {
      errors.remark = 'Remark is required.';
    }

    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    const formData = new FormData();
    formData.append('id', id);
    formData.append('remark', remark);
    formData.append('emp_id', empId);
    try {
      const response = await dashboardApi.post('add-remark-on-kra-kpis', formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        handle_close();
        sales_inner_page_details();
      } else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the product',
      });
    }
  }

  const productdetails = async () => {
    try {
      const endpoint = 'get-product-list';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setproductlist(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const get_emp_list = async () => {
    try {
      const endpoint = 'get-sales-managers';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        getemp_list(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (product) {
      const fetchData = async () => {
        try {
          const endpoint = `get-service-list-by-product-id/${product}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            setservicelist(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [product]);

  useEffect(() => {
    if (selectedmanagers) {
      setselectedemp([]);

      const fetchData = async () => {
        try {
          const endpoint = `get-all-manager-employee/${selectedmanagers}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            getmanagersteam(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [selectedmanagers]);

  const sales_inner_page_details = async () => {
    setLoading(true); // Start loading
    try {
      const response = await dashboardApi.get('missing-kra-kpi-history', {
        params: {
          emp_id: empId,
          page: currentPage + 1,
        },
      });
      if (response.data.status === 200) {
        const responseData = response.data.data;
        const kra_kpi_data = responseData.data || responseData;
        get_inner_page_details(kra_kpi_data);
        setNumber(response.data.last_page);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
    sales_inner_page_details(selected.selected); // Update the data for the selected page
  };

  const sales_inner_page_details_filter = async () => {
    setLoading(true); // Start loading
    try {
      const response = await dashboardApi.get('missing-kra-kpi-history', {
        params: {
          emp_id: empId,
          start_date: start_date,
          end_date: end_date,
          page: currentPage + 1,
        },
      });
      if (response.data.status === 200) {
        const responseData = response.data.data;
        const kra_kpi_data = responseData.data || responseData;
        get_inner_page_details(kra_kpi_data);
        setNumber(response.data.last_page);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <div className="container-fluid mt-4">
        <Link className='btn btn-info btn-sm' to='/sales-dashboard' >Back</Link>
          <div className="card">
            <div style={{ float: 'right' }}></div>
            <div className='row'>
              <div className='col-md-4'>
                <input type='date'
                  className='form-control'
                  value={start_date}
                  onChange={(e) => setstart_date(e.target.value)}
                />
              </div>

              <div className='col-md-4'>
                <input type='date'
                  className='form-control'
                  value={end_date}
                  onChange={(e) => setend_date(e.target.value)}
                />
              </div>
            </div>
            <div className="card-body">
              {loading ? ( // Loader
                <div className="text-center">
                  <CircularProgress />
                </div>
              ) : (
                <table className="table">
                  <thead>
                    <tr>
                      <td>S.No</td>
                      <td>Emp</td>
                      <td>Manager</td>
                      <td>Missing No</td>
                      <td>Date</td>
                      <td>Emp Remark</td>
                      <td>Manager Remark</td>
                      <td>CEO Remark</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {inner_page_details.map((row, index) => (
                      <tr key={row.id}>
                        <td>{index + 1 + currentPage * postPerPage}</td>
                        <td>{row.emp_name}</td>
                        <td>{row.manager}</td>
                        <td>{row.count}</td>
                        <td>{row.date}</td>
                        <td>{row.emp_remark}</td>
                        <td>{row.manager_remark}</td>
                        <td>{row.superadmin_remark}</td>
                        <td><button className='btn btn-info btn-sm' onClick={() => add_remark(row.id)}>Remark</button></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            {inner_page_details.length > 0 && (
              <Pagination className="custom-pagination">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={number}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  pageLinkClassName={"page-link-style"}
                  breakLinkClassName={"break-link-style"}
                  previousLinkClassName={"prev-link-style"}
                  nextLinkClassName={"next-link-style"}
                />
              </Pagination>
            )}
          </div>
        </div>
        <Dialog open={openModal} fullWidth PaperProps={{ style: { maxWidth: 'xl', padding: '40px' } }}>
          <DialogTitle>Remark</DialogTitle>
          <DialogContent>
            <form>
              <div className="form-group col-md-12">
                <label htmlFor="short name" style={{ fontSize: '16px', height: '20px' }}>Remark</label>
                <textarea rows={5} cols={5} style={{ height: '50px' }}
                  value={remark}
                  onChange={(e) => setremark(e.target.value)}
                  className="form-control"
                  placeholder="remark"
                />
                {validationErrors.remark && (
                  <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remark}</div>
                )}
              </div>
            </form>
          </DialogContent>

          <DialogActions>
            <Link className="btn btn-danger" onClick={handle_close}>
              Close
            </Link>
            <Link className="btn btn-info" onClick={handle_save}>
              Add
            </Link>
          </DialogActions>
        </Dialog>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
      <ToastContainer />
    </DashboardLayout>
  );
}

export default Missingkrakpi;
