// @mui material components
import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";
import Pagination from 'react-bootstrap/Pagination';
//import 'bootstrap/dist/css/bootstrap.min.css';
import ReactPaginate from 'react-paginate';
import TicketRaiseHeader from 'layouts/common_components/ticket_raise_header';

function RaiseList() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [raiselist, setraiselist] = useState([])
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(5);
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
 useEffect(() => {
   fetchData();
  }, []);
   const fetchData = async (page = 0) => {
    try {
    const endpoint = `ticket-raise-list/${emp_id}`;
    const response = await dashboardApi.get(endpoint, {
     params: {
       per_page: postPerPage,
       page: page + 1,
      }
     });

      if (response.data.status === 200) {
        //setraiselist(response.data.data);
        const responseData = response.data.data;
        const ticketdata = responseData.data || responseData;
        setraiselist(ticketdata);
        setNumber(response.data.last_page);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
    fetchData(selected.selected);
    //fetchData(selected.selected);
   // console.log(selected);


  };
const handlecloseClick = async (id) =>{
  const isConfirm = await Swal.fire({
    title: 'Are you sure?',
    text: 'Are You Want To Closed Ticket!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Close it!'
  }).then((result) => {
    return result.isConfirmed;
  });

  if (!isConfirm) {
    return;
  }
    const formData = new FormData();
    formData.append('ticket_id',id);
    formData.append('emp_id',emp_id);
  try {
      const response = await dashboardApi.post('close-ticket', formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        //navigate("/ticket-receive");
        //handleCloseModal();
        fetchData();

      }else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while close ticket',
      });
    } 
  };
//console.log(raiselist);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
            <TicketRaiseHeader/>
              <div className="card-body">
                 {loading ? (
                  <p>Loading...</p>
                ) : (
                 <div className="table-responsive">
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Ticket Id</td>
                  <td>Request To Dept</td>
                  <td>Attribute</td>
                  <td>Subattribute</td>
                  <td>Action Time(in Hour)</td>
                  <td>Completion Time(in Hour)</td>
                  <td>Description</td>
                  <td>Action</td>
               </tr>
               {raiselist.map((ticket, index) => (
                <tr key={ticket.id}>
                  <td>{index + 1}</td>
                  <td>{ticket.ticket_id}</td>
                  <td>{ticket.department_name}</td>
                  <td>{ticket.attribute_name}</td>
                  <td>{ticket.name}</td>
                  <td>{ticket.action_time}</td>
                  <td>{ticket.completion_time}</td>
                 <td>
                  <Link to={`/view-ticket-status/${ticket.ticket_id}`} className='btn btn-success me-2 btn-sm'>
                              View 
                  </Link>
                  </td>
                  <td>
                  {ticket.close_status === 0 ? (
                       <Link className='btn btn-danger me-2 btn-sm' onClick={() => handlecloseClick(ticket.ticket_id)}>
                          Close 
                       </Link>
                     ) : (
                 <Link className='btn btn-danger me-2'>
                    Closed
                 </Link>
                )}
                &nbsp; &nbsp;

                <Link to= {`/edit-ticket/${ticket.ticket_id}`} className='btn btn-info btn-sm'>Edit</Link>

                    </td>
               </tr>
              ))}
            </table>
            </div>
             )}
              {raiselist.length > 0 && (
                <Pagination className="custom-pagination">
                      <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
               )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default RaiseList;